import React, { Component } from 'react';

const Cat = (
  <svg version="1.1" key="Cat" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 346.675 346.676">
		  <path d="M26.753,210.905c-4.68,19.271-1.375,33.856,8.258,46.243c9.633,12.387-1.102,19.543-9.082,23.398
  			s-11.012,6.332-11.562,11.836s6.883,26.978,10.461,30.556s11.563,11.285,16.789,19.27c5.231,7.98,12.11,2.754,12.11,2.754
  			c4.953,3.855,9.91,0,9.91,0c8.809,4.129,14.039-1.652,14.039-1.652c4.402-5.23-4.953-10.734-4.953-10.734
  			c-5.781-10.184-10.184-1.926-11.836-4.129s-3.027-2.477-11.836-12.66s14.039-6.883,22.02-8.809s23.122-12.109,23.122-12.109
  			c0,12.109,4.129,10.184,10.184,16.516s8.809,10.461,16.789,13.762c7.98,3.305,11.836,2.754,18.993,7.434s22.02,3.855,22.02,3.855
  			c6.605,2.204,12.938,0,12.938,0c15.965,0,9.359-5.504,3.578-12.938s-17.34-1.102-22.57-4.68s-18.168-9.082-24.774-12.938
  			c-6.605-3.855,0-10.734,0-17.066s4.953-11.285,4.953-11.285h25.598c0,0,19.816-1.375,25.047,9.359s13.211,12.938,13.488,18.719
  			c0.278,5.781-4.68,6.332-4.68,6.332c-11.012-3.027-11.285,14.59-11.285,14.59c13.488,17.892,39.638-7.434,39.638-7.434
  			c30.277-2.754,28.074-47.344,28.074-47.344c6.055-4.402,15.691-15.965,22.02-26.427c6.332-10.461,24.223-18.441,40.188-10.734
  			c15.965,7.707,18.719-5.23,18.719-5.23c5.504,0.824,7.156-6.332,7.156-6.332c7.156-7.98-5.504-9.91-5.781-29.727
  			s-16.789-23.399-15.965-29.176c0.824-5.781,17.617-24.223,13.488-22.571c-3.603,1.44-18.739,8.964-22.53,10.853
  			c-0.126-0.498-0.375-1.208-0.868-2.317c-2.204-4.953-26.427,10.734-26.427,10.734s-38.396,22.709-45.83,24.362
  			c-7.435,1.652-6.605-3.305-15.688-2.477c-9.083,0.824-13.628,1.652-54.501-15.692c-40.877-17.34-115.325,10.546-115.325,10.546
  			S-8.608,82.798,40.935,41.924S24.215-33.009,8.111,56.167C-7.992,145.344,31.433,191.64,26.753,210.905z"/>
    </svg>
)

const Fox = (
  <svg version="1.1" key="Fox" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 554.559 554.559" >
		<path d="M531.14,226.158c-1.771-14.162-19.033-33.195-19.033-33.195c-5.752-44.26-20.804-20.359-20.804-20.359
			c-18.147-13.28-15.491,17.262-15.491,17.262c-25.672,0-101.359,41.608-148.721,30.098c-47.36-11.509-129.243-1.326-143.408,3.982
			s-36.736-0.886-47.801,9.294s-42.048,16.377-89.409,42.489c-47.356,26.116-46.471,50.016-46.471,50.016
			c42.493,19.474,173.506-34.966,173.506-34.966c0,3.542,2.656,4.427,2.656,4.427c-12.395,4.868-24.345,19.033-33.195,20.359
			c-8.854,1.326-11.065,5.753-11.065,5.753c-46.031,55.329-30.983,54.443-30.983,54.443c1.771,7.083,7.523,2.212,7.523,2.212
			c3.982,0.885,5.753,2.211,9.739-8.854c3.986-11.065,23.901-41.608,51.788-42.049c27.883-0.444,44.26-10.18,44.26-10.18
			c-28.772,27.887-30.539,13.721-27.442,29.653s18.148,36.736,23.023,40.278c4.875,3.541,33.191,0,33.191,0
			c-4.868-7.083-19.474-4.721-19.474-4.721s-3.097-8.561-8.854-14.758c-5.753-6.197-11.95-24.786,16.818-27.887
			s45.145-25.23,49.572-32.31c4.427-7.083,1.771-17.703,24.346-6.638c22.574,11.064,68.164,13.28,68.164,13.28
			s-3.981,6.197,8.854,23.9c12.836,17.703,3.542,20.804-11.95,19.919c-15.491-0.886-15.047,14.161-15.047,14.161
			c29.213,5.312,21.244-4.427,40.277-4.867s14.166-22.574,10.18-30.539c-3.981-7.968-5.752-24.345-5.752-24.345
			s21.688-5.312,21.688,12.395s31.869,23.46,45.146,26.557c13.28,3.097,34.966,12.836,40.723,16.818
			c5.757,3.981,19.033,19.033,28.768,12.835c9.739-6.197,12.836-14.606-1.771-15.932c-14.606-1.326-41.163-27.442-50.017-29.654
			c-8.854-2.211-29.213-18.147-33.195-20.804c-3.981-2.656,2.212-19.918,4.868-27.001s36.296-42.489,53.113-25.23
			c16.817,17.262,30.539,12.835,25.671,8.854c-4.867-3.982-20.208-22.575-20.208-22.575c31.579,25.672,41.894,9.739,39.237,7.524
			C551.499,253.6,532.911,240.324,531.14,226.158z"/>
</svg>
)

const Dog = (
  <svg version="1.1" key="Dog" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 441.23 441.23">
   <path d="M440.651,132.739c-0.888-2.354-2.721-4.229-5.054-5.171L396.39,111.75c-2.435-12.666-12.056-22.81-24.436-26.008
  	l-2.115-24.363c-0.309-3.565-2.701-6.608-6.093-7.751c-3.391-1.142-7.137-0.167-9.541,2.484l-10.062,11.099l-6.22-21.766
  	c-1.072-3.752-4.44-6.386-8.341-6.521c-3.924-0.139-7.444,2.258-8.773,5.928L274.34,173.047c0,0-172.127,8.035-172.199,8.039
  	c-35.296,2.121-68.735-16.765-85.167-48.119c-2.309-4.402-7.748-6.102-12.149-3.794c-4.403,2.308-6.101,7.747-3.794,12.149
  	c14.69,28.03,40.708,47.75,70.541,54.93c-3.097,3.609-9.751,12.554-13.642,26.617c-4.231,15.291-5.756,39.667,9.801,70.619
  	L42.499,327.76c-1.474,2.001-2.054,4.523-1.603,6.968l11.105,60.218c0.787,4.268,4.51,7.367,8.851,7.367h11.312
  	c2.669,0,5.2-1.185,6.911-3.233c1.71-2.05,2.422-4.753,1.944-7.378l-7.032-38.627l74.401-55.853c0.131-0.099-0.126,0.107,0,0
  	c7.114-5.975,12.278-12.556,15.972-19.322c38.777,6.808,91.844,16.02,125.857,21.498l14.986,95.314
  	c0.689,4.377,4.46,7.602,8.891,7.602h9.308c4.971,0,9-4.029,9-9V296.56c8.449-5.77,11.469-15.42,13.472-24.871
  	c0.063-0.297,20.022-92.416,20.022-92.416c3.762,0.627,7.561,0.956,11.388,0.956c26.356,0,50.492-15.431,62.987-40.271
  	C441.401,137.712,441.539,135.094,440.651,132.739z"/>
  </svg>
)

const Chicken = (
  <svg version="1.1" key="Chicken" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 25.289 25.289">
	  <path d="M25.287,5.168c0.053-0.948-1.138-1.348-1.396-1.989c0.177-0.183,0.373-1.325,0.323-1.683
  		c-0.049-0.346-0.829,0.445-0.967,0.287c-0.157-0.183,0.109-0.971-0.18-1.377c-0.147-0.207-0.483,1.327-0.737,1.442
  		c-0.183,0.082-0.502-0.351-0.828-0.95c-0.533-0.979-0.239,1.129-0.188,1.531c-1.413,0.216-2.298,1.153-2.696,2.369
  		c-0.438,1.337-1.661,4.313-3.911,4.454c-2.703,0.17-5.718-2.358-7.14-3.814C6.81,4.665,6.257,2.439,4.931,2.611
  		C4.332,2.689,3.861,3.535,3.16,3.717c0,0-3.6,0.889-3.115,1.856c0.286,0.569,1.859,1.316,3.06,1.82
  		C3.158,7.414,3.187,7.474,3.17,7.528c-0.017,0.056-0.072,0.089-0.128,0.08c-1.03-0.183-2.054-0.222-1.03,0.657
  		C2.265,8.482,3.214,8.94,4.104,9.347c0.053,0.023,0.08,0.084,0.06,0.139C4.145,9.541,4.088,9.573,4.031,9.559
  		C3.31,9.384,2.655,9.308,2.695,9.623c0.089,0.71,2.828,2.794,2.828,2.794c-0.347,0.426,1.065,1.955,1.29,2.147
  		c-0.324-0.108-0.673,0.016-0.214,0.431c0.676,0.614,0.826,1.604,1.404,2.178c1.699,1.689,4.248,0.415,5.561,3.267
  		c0.068,0.148,0.367,0.747,0.624,1.27c0.077,0.156,0.077,0.339,0.001,0.495c-0.077,0.156-0.222,0.269-0.392,0.304l-1.699,0.352
  		c-0.247,0.051-0.421,0.28-0.397,0.537c0.026,0.274,0.27,0.478,0.545,0.451l1.68-0.157c0.056-0.005,0.11,0.024,0.137,0.074
  		s0.02,0.111-0.017,0.155l-0.134,0.161c-0.176,0.212-0.147,0.527,0.065,0.704c0.093,0.077,0.206,0.115,0.319,0.115
  		c0.144,0,0.285-0.062,0.385-0.181l0.707-0.851c0.134-0.161,0.346-0.234,0.551-0.19l2.476,0.532c0.246,0.053,0.498-0.087,0.58-0.331
  		c0.088-0.262-0.054-0.546-0.316-0.634l-2.234-0.747c-0.203-0.067-0.351-0.246-0.379-0.458c-0.054-0.398-0.124-0.9-0.173-1.284
  		c-0.033-0.258,0.125-0.502,0.373-0.577c1.134-0.34,1.741-1.725,2.477-2.658c0.337-0.43,0.813-0.722,1.348-0.831
  		c2.928-0.597,3.921-3.638,4.229-6.854c0.182-1.898-0.71-2.667-1.182-4.08C23.731,5.579,24.381,4.692,25.287,5.168z M22.065,4.111
  		c-0.218,0-0.393-0.176-0.393-0.394c0-0.22,0.176-0.396,0.393-0.396c0.223,0,0.394,0.176,0.394,0.396
  		C22.459,3.935,22.287,4.111,22.065,4.111z"/>
    </svg>
)

const Dolphin = (
  <svg version="1.1" key="Dolphin" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 294.868 294.868">
   <path d="M284.395,115.13c-3.853,4.085-9.53,4.085-11.396,4.085c-3.46,0-7.738-0.573-13.868-1.859
  	c-4.815-1.01-8.961-2.017-12.617-2.904c-7.192-1.747-12.39-3.01-17.283-3.01c-2.758,0-7.79,1.195-13.119,2.461
  	c-8.297,1.971-18.622,4.423-28.609,4.423c-10.083,0-20.696-0.784-27.383-1.389c-2.221,8.326-7.505,22.056-19.499,31.884
  	c-16.307,13.36-28.397,19.32-28.904,19.567c-3.097,1.51-6.821,0.719-9.034-1.922c-2.213-2.639-2.345-6.445-0.319-9.23
  	c0.067-0.095,8.217-11.616,7.904-24.564c-0.124-5.151-0.311-9.187-0.502-12.261c-10.157,2.994-27.451,9.687-36.372,22.286
  	C59.88,161.78,54.336,191.16,55.821,200.798c1.546,10.042,1.731,10.042,6.496,10.042c10.382,0,19.104,0.425,28.928,5.773
  	c8.69,4.729,13.476,9.903,13.992,10.478c1.949,2.165,2.468,5.263,1.331,7.944c-1.137,2.683-3.724,4.464-6.635,4.568
  	c-2.808,0.105-11.305,0.813-16.766,2.795c-2.9,1.053-5.398,2.285-7.814,3.477c-3.865,1.907-7.516,3.708-11.775,4.211
  	c-1.385,0.164-2.807,0.244-4.153,0.219c-1.57,3.647-4.242,7.994-8.736,11.443c-3.44,2.641-6.94,4.27-10.028,5.706
  	c-3.792,1.765-6.788,3.159-9.088,6.011c-6.028,7.475-8.051,15.58-8.07,15.661c-0.788,3.237-3.622,5.583-6.95,5.734
  	c-0.115,0.006-0.231,0.008-0.346,0.008c-3.191,0-6.054-2.012-7.095-5.056c-0.469-1.367-4.422-13.896,0.839-32.235
  	c1.881-6.556,4.795-12.231,7.612-17.721c4.584-8.929,8.543-16.64,6.882-26.089c-7.808-44.411-7.269-80.521,1.601-107.323
  	C39.838,64.76,73.226,41.535,79.779,37.304c3.251-2.101,6.177-3.949,8.643-5.485c-2.287-1.822-5.194-3.736-8.65-5.205
  	c-7.866-3.344-11.172-4.258-12.088-4.476c-0.059,0.002-0.116,0.002-0.173,0.002c-3.265,0.001-5.775-2.155-6.73-5.339
  	c-0.992-3.306,0.851-6.855,3.822-8.61C65.168,7.856,78.681,0,96.49,0c3.675,0,7.326,0.337,10.851,1.002
  	c10.983,2.073,18.745,5.17,26.251,8.164c7.329,2.924,14.252,5.686,24.588,7.863c5.021,1.058,9.645,1.874,14.116,2.664
  	c15.383,2.716,28.668,5.062,48.531,15.205c25.924,13.24,40.501,27.726,41.047,40.787c0.115,2.753,0.055,5.213-0.181,7.375
  	c2.426,1.213,5.374,2.746,8.352,4.44c12.123,6.895,17.207,12.059,17.546,17.822C287.826,109.332,286.751,112.631,284.395,115.13z"/>
  </svg>
)

const Elephant = (
  <svg version="1.1" key="Elephant" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 576.667 576.667">
		<path d="M17.932,381.487c5.031-5.904,10.278-11.657,14.921-17.858c5.969-7.965,10.555-16.749,14.153-26.088
			c3.529-9.168,7.65-18.111,11.359-27.214c1.607-3.94,2.876-8.025,4.207-12.076c0.853-2.6,2.595-4.26,5.516-5.643
			c0,1.555,0.012,2.803,0,4.051c-0.204,19.111-0.49,38.222-0.583,57.333c-0.045,9.232,2.586,18.078,4.565,27.001
			c0.657,2.966,1.542,5.879,2.326,8.812c1.44,5.41,0.551,10.625-1,15.896c-2.281,7.76-4.26,15.605-6.369,23.415
			c-1.408,5.218-3.06,10.388-4.169,15.671c-2.55,12.146-1.958,24.37-0.253,36.564c0.901,6.431,1.832,12.804-0.159,19.246
			c-0.775,2.513-1.33,5.242-1.229,7.842c0.188,4.77,3.387,7.854,8.115,8.348c4.521,0.473,9.054,0.914,13.591,1.122
			c5.94,0.273,11.905,0.62,17.837,0.367c7.14-0.307,14.248-1.257,21.375-1.82c1.987-0.159,2.317-1.252,2.097-2.827
			c-0.314-2.24-0.709-4.468-1.102-6.851c0.327-0.163,0.682-0.379,1.065-0.522c3.211-1.187,4.578-4.242,2.717-7.147
			c-1.803-2.815-4.063-5.374-6.316-7.866c-2.06-2.281-4.675-4.104-6.516-6.532c-2.766-3.66-5.23-7.577-7.515-11.562
			c-1.987-3.469-1.534-7.377-0.494-10.987c1.697-5.852,3.264-11.857,5.948-17.271c2.799-5.646,6.365-11.089,10.486-15.854
			c5.202-6.018,11.326-11.24,17.014-16.847c5.777-5.688,9.723-12.407,11.562-20.363c0.722-3.125,1.665-6.205,2.697-9.245
			c0.188-0.547,1.314-1.146,1.967-1.102c7.487,0.481,14.957,1.281,22.448,1.579c4.867,0.196,9.768-0.298,14.651-0.478
			c1.224-0.045,2.456-0.036,3.68-0.081c9.792-0.343,19.621-0.282,29.359-1.184c6.475-0.6,12.53-3.374,18.34-6.43
			c4.153-2.188,8.4-4.194,12.717-6.337c2.823,2.864,4.345,6.414,5.267,10.262c2.852,11.922,3.717,24.093,4.447,36.275
			c0.388,6.442,1.057,12.885,1.044,19.327c-0.012,7.144-0.771,14.279-0.983,21.428c-0.114,3.835,0.596,7.711,0.314,11.521
			c-0.478,6.414-1.297,12.815-2.293,19.177c-0.327,2.08-1.616,4.019-2.509,6.001c-2.428,5.369-1.877,10.103,1.795,13.55
			c0.738,0.694,1.954,1.289,2.929,1.253c10.465-0.4,20.922-1.008,31.387-1.408c6.725-0.257,13.461-0.293,20.191-0.326
			c0.895-0.004,2.029,0.347,2.645,0.951c2.387,2.317,5.406,2.321,8.324,2.362c8.334,0.118,16.67,0.045,25.01,0.036
			c3.477-0.004,6.969,0.176,10.42-0.105c2.277-0.184,4.508-0.996,6.736-1.607c1.256-0.348,2.432-1.017,3.701-1.266
			c5.295-1.027,5.365-2.203,4.328-7.642c-0.342-1.795-2.045-3.35-3.174-4.978c-1.061-1.53-2.139-3.056-3.318-4.5
			c-0.395-0.485-1.234-0.592-1.676-1.057c-3.938-4.125-8.459-7.806-10.742-13.248c-0.568-1.347-0.895-2.844-1.057-4.3
			c-1.143-10.155-2.596-20.294-3.188-30.482c-0.449-7.728,0.277-15.52,0.393-23.284c0.094-6.491-0.082-12.986,0.121-19.474
			c0.242-7.687,0.332-15.419,1.25-23.036c1.465-12.134,1.281-24.264,0.652-36.377c-0.531-10.237-1.938-20.429-2.828-30.653
			c-0.064-0.775,0.697-1.979,1.42-2.411c7.863-4.688,15.77-9.311,23.713-13.855c8.898-5.088,17.854-10.082,27.662-15.61
			c1.689,1.946,3.627,4.3,5.691,6.532c1.836,1.979,3.865,3.778,5.713,5.744c2.371,2.518,4.77,5.022,6.916,7.724
			c2.102,2.644,3.879,3.627,5.531,2.505c1.91-1.293,2.457-4.912,1.021-7.168c-0.557-0.869-1.201-1.705-1.926-2.436
			c-5.549-5.606-8.111-12.865-11.057-19.915c-0.27-0.645-0.561-1.281-1.041-2.375c3.328-1.559,6.646-3.17,10.016-4.659
			c2.311-1.02,4.66-1.979,7.055-2.771c4.488-1.485,7.891-4.41,10.889-7.895c1.857-2.154,4.273-3.37,6.672-1.759
			c5.879,3.946,12.672,4.043,19.217,5.263c0.342,0.065,0.709,0.062,1.027,0.184c1.352,0.522,2.848,1.118,3.686-0.526
			c0.457-0.898,0.232-2.146,0.371-3.946c1.256-1.734,3.928-1.061,6.266-1.338c2.588-0.31,5.211-0.58,7.811-0.486
			c1.867,0.069,3.754,0.625,5.557,1.196c11.17,3.541,22.652,4.635,34.125,2.791c7.748-1.245,15.684-2.729,22.346-7.609
			c4.424-3.239,8.467-6.732,9.502-12.489c0.217-1.179,0.033-2.432-0.395-4.072c-11.465,10.592-25.285,10.49-39.865,10.241
			c3.773-3.758,9.762-4.892,9.334-11.869c-5.006,1.832-9.648,3.615-14.354,5.227c-5.133,1.758-10.334,3.195-15.98,0.686
			c2.938-1.041,5.797-2.546,8.824-3.036c10.527-1.697,18.078-7.414,23.994-16.047c3.787-5.528,6.707-11.338,8.598-17.74
			c1.99-6.744,1.938-13.635,1.631-20.539c-0.375-8.417-3.889-15.994-6.602-23.782c-2.791-8.013-4.332-16.324-3.301-24.733
			c1.082-8.776,3.998-17.108,8.703-24.713c2.102-3.395,3.982-6.944,6.299-10.179c4.457-6.222,10.461-10.425,17.797-12.697
			c2.061-0.636,4.076-1.436,6.145-2.04c1.918-0.559,1.67-2.313,1.168-3.178c-1.955-3.382-1.307-6.989-1.379-10.535
			c-0.078-3.521-2.363-5.528-5.064-3.562c-4.447,3.243-9.682,3.68-14.492,5.528c-9.566,3.676-18.098,9.196-25.352,16.32
			c-7.561,7.421-14.199,15.557-18.205,25.737c-3.342,8.495-7.434,16.752-8.957,25.786c-1.717,10.212-1.529,20.481,0.527,30.743
			c0.99,4.941,1.064,10.086,1.244,15.149c0.045,1.306-0.844,2.832-1.705,3.941c-2.543,3.276-5.283,6.393-7.463,8.997
			c-4.316-1.489-8.322-3.02-12.416-4.244c-4.133-1.232-7.895-2.982-11.305-5.688c-2.713-2.154-5.838-3.774-8.691-5.761
			c-4.998-3.472-9.861-7.14-14.9-10.542c-3.533-2.387-7.176-4.644-10.93-6.663c-6.123-3.296-12.312-6.487-18.592-9.474
			c-5.818-2.762-12.154-2.428-18.303-1.893c-8.584,0.747-16.766,3.215-24.229,7.67c-6.641,3.966-13.207,8.062-19.832,12.057
			c-3.631,2.187-7.65,2.807-11.828,2.717c-2.338-0.049-4.709-0.273-7.01,0.013c-3.736,0.465-6.459-1.934-9.521-3.162
			c-7.193-2.885-14.521-4.827-22.244-5.508c-6.158-0.542-12.27-1.566-18.422-2.187c-10.004-1.008-19.951-0.881-29.388,3.17
			c-5.504,2.362-10.792,5.227-16.304,7.556c-1.448,0.612-3.452,0.379-5.088,0.024c-6.373-1.383-12.689-3.023-19.049-4.476
			c-5.667-1.293-11.396-2.317-17.03-3.733c-10.216-2.57-19.812,0.429-29.364,3.219c-6.928,2.024-13.737,4.545-20.42,7.283
			c-4.737,1.942-9.347,4.341-13.708,7.026c-7.087,4.361-14.312,8.646-20.771,13.843c-6.973,5.614-13.411,11.979-19.563,18.503
			c-5.483,5.81-10.412,12.167-15.3,18.507c-3.705,4.806-7.315,9.755-10.311,15.01c-3.872,6.797-7.385,13.839-10.473,21.024
			c-1.771,4.108-2.423,8.699-3.538,13.084c-1.179,4.644-2.146,9.343-3.492,13.934c-2.081,7.087-4.3,14.137-6.667,21.134
			c-1.893,5.603-3.953,11.155-6.189,16.631c-1.901,4.667-4.104,9.212-6.267,13.77c-2.991,6.308-5.773,12.737-9.205,18.805
			c-2.856,5.047-6.202,9.91-9.951,14.325c-1.971,2.313-5.483,3.239-7.952,5.23c-3.721,3.003-6.418,6.801-7.209,12.591
			c2.689-1.232,4.912-2.068,6.957-3.224C11.449,389.32,14.647,385.338,17.932,381.487z M305.273,468.456
			c1.379-2.771,2.195-5.933,2.689-9.009c1.387-8.588,2.514-17.218,3.668-25.843c0.967-7.226,1.836-14.463,2.75-21.697
			c0.176,0.013,0.352,0.028,0.525,0.041c1.199,4.974,2.428,9.942,3.596,14.921c1.975,8.429,2.424,16.907,1.316,25.532
			c-0.641,5.002-1.443,10.253,0.883,14.985c2.789,5.672,2.158,11.335,0.99,17.092c-0.693,3.431-1.68,6.801-2.736,10.991
			c-1.412-1.705-2.307-2.717-3.127-3.786c-2.631-3.456-5.156-6.993-7.865-10.388C304.875,477.44,302.695,473.638,305.273,468.456z"
			/>
      </svg>
)

const Mouse = (
  <svg version="1.1" key="Mouse" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 544.345 544.345">
		<path d="M532.025,368.16c-3.99-9.033-8.147-18.013-11.709-27.218c-4.61-11.913-4.386-11.999-0.849-24.725
			c3.554-12.771-0.086-23.378-9.964-29.054c-4.888-2.807-6.242-2.28-8.71,2.995c-2.424,5.173-4.896,10.33-7.598,15.365
			c-1.313,2.448-3.187,4.594-5.813,8.307c-2.75-5.157-4.655-9.318-7.087-13.142c-3.293-5.178-6.096-11.224-10.71-14.876
			c-8.278-6.544-14.387-6.52-20.2,1.926c-3.562,5.174-6.341,10.947-8.911,16.699c-0.979,2.195-1.411,3.681-4.19,3.922
			c-11.371,0.971-22.562,2.505-33.713,5.593c-5.871,1.628-13.268-0.648-19.58-2.509c-22.681-6.679-45.61-10.938-69.241-7.438
			c-41.62,6.164-68.323,30.938-83.807,68.943c-8.07,19.812-11.566,40.417-8.417,61.792c0.657,4.451,2.285,8.76,3.439,13.142
			c0.404,1.53,0.784,3.064,1.139,4.504c-23.986,5.215-49.335,7.736-72.648-0.775c-22.55-8.233-40.588-26.312-42.461-50.983
			c-2.056-27.05,16.349-52.102,33.791-70.719c19.85-21.188,42.897-39.327,58.973-63.831c31.167-47.52,35.814-109.483,6.45-158.839
			c-6.259-10.522-13.986-20-22.803-28.217c-1.082-1.717-2.668-3.174-4.598-4.19c-0.086-0.045-0.171-0.086-0.253-0.126
			c-27.466-23.134-64.137-34.48-100.409-27.344c-31.991,6.292-60.029,25.374-76.606,53.485c-0.665,1.126-0.963,2.354-0.983,3.598
			c-3.66,4.407-5.157,10.237-4.337,16.483c1.379,10.486,10.265,17.422,20.322,18.666c22.2,2.75,32.722-19.633,46.304-32.362
			c28.38-26.594,69.87-32.085,104.534-15.545c9.453,8.454,17.532,18.503,23.664,29.38c12.868,22.819,17.087,48.977,13.859,74.839
			c-3.431,27.516-15.594,53.012-33.341,74.125c-18.728,22.281-42.404,39.915-60.14,63.094
			c-17.042,22.269-29.062,51.477-23.101,79.809c5.541,26.336,26.267,45.998,50.408,56.178c27.1,11.428,57.349,9.339,85.717,3.345
			l0.106,1.021c1.799-0.326,4.223,0.151,5.716,1.179c22.456,15.513,47.384,18.744,73.815,15.651
			c11.48-1.343,22.436,1.081,32.542,6.956c1.506,0.873,3.357,1.15,5.446,1.836l0.71-5.218c2.57,0.53,4.811,0.987,7.748,1.591
			c-0.004-1.615-0.012-3.104-0.016-4.851c1.905,0.036,3.859,0.077,6.25,0.126c-0.849-1.991-1.6-3.754-2.566-6.03
			c1.648,0.347,2.95,0.624,5.777,1.22c-1.804-2.909-2.529-5.422-4.19-6.507c-5.145-3.358-10.497-6.496-16.087-9.018
			c-1.938-0.873-4.892-0.045-7.214,0.612c-4.194,1.184-8.213,2.995-12.407,4.187c-1.604,0.457-3.484-0.069-5.431-0.764
			c9.719-6.471,15.582-17.992,28.862-19.376c6.765-0.701,13.297-3.517,20-5.083c1.518-0.355,3.554,0.094,4.933,0.897
			c2.302,1.338,4.476,3.056,6.341,4.965c13.072,13.366,29.938,20.14,46.536,27.594c4.606,2.068,8.078,6.658,12.306,10.294
			c0.412-1.444,0.975-3.424,1.75-6.145c3.239,1.354,6.386,2.664,9.56,3.99c2.342-5.427-2.893-8.332-5.933-13.261l10.987,1.498
			c-1.514-2.559-2.464-6.03-4.716-7.467c-2.366-1.514-5.978-1.081-10.551-1.701c2.696-1.587,3.994-2.351,5.532-3.256
			c-4.19-4.374-9.021-2.733-13.154-1.069c-4.957,2-8.964,1.285-12.293-2.358c-3.753-4.112-3.668-9.229-2.244-14.3
			c1.028-3.664,3.383-2.13,5.598-1.257c5.296,2.085,10.547,4.337,15.978,5.998c2.636,0.808,5.655,0.261,8.458,0.66
			c2.439,0.348,5.063,0.706,7.156,1.865c3.998,2.211,7.679,4.994,11.489,7.54c0.555-0.38,1.114-0.76,1.669-1.135
			c-1.191-3.011-2.387-6.021-3.55-8.947c4.443-0.653,8.621,1.485,13.709,7.548l0.779-5.72c1.783,0.412,3.439,0.795,5.145,1.191
			c0.155-5.749-5.602-11.869-11.04-11.886c-1.673-0.004-3.35,0.372-5.022,0.376c-14.076,0.036-20.307-4.685-23.664-18.446
			c-0.408-1.664,0.665-4.259,1.905-5.675c11.815-13.493,27.312-18.205,44.7-18.561c7.568-0.154,15.011-0.628,21.604,4.187
			c1.105,0.808,2.897,1.016,4.346,0.946c5.871-0.285,11.75-0.641,17.597-1.248c10.09-1.053,13.146-5.806,9.363-15.007
			C539.468,384.011,535.563,376.165,532.025,368.16z"/>
    </svg>
)

const Lion = (
  <svg version="1.1" key="Lion" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 249.072 249.072">
    <path d="M243.153,65.825c-9.978-5.69-6.233-10.809-10.392-13.727c-4.158-2.904-6.514-3.043-6.514-3.043s1.878-8.875-8.139-8.73
	c0,0-13.26-23.551-39.111-0.809c0,0,3.305-1.152,5.614,0c2.309,1.164-18.997,4.469-27.919,20.652c5.95-2.637,6.613-0.76,6.613,2.149
	c0,10.068-11.775,23.676-34.387,24.969c-9.999,0.584-16.919-4.859-40.331-4.859c-23.419,0-38.585,19.725-45.903,48.662
	c-7.322,28.936-10.979,32.932-18.297,32.932c-7.316,0-14.301-9.311-13.969-18.625c0.331-9.316,1.917-18.195,6.766-21.523
	c4.852-3.326,0.278-12.041-7.342-7.611c-7.621,4.436-9.974,16.469-9.838,29.361c0.139,12.877,5.543,30.34,23.691,30.34
	c18.151,0,25.493-18.846,25.493-18.846s-1.803,20.645,4.295,51.68c-0.557,4.148-2.215,10.67,9.145,10.531
	c11.358-0.141,14.413-0.695,14.131-7.063c-0.274-6.369-3.879-7.07-6.788-7.07c-2.907,0-7.343,0.83-6.929-4.855
	c0.42-5.67,5.047-43.08,33.724-58.592c14.413,5.393,23.578,13.857,52.392,5.539c1.53-0.283,4.576,8.438,4.576,19.25
	c0,10.809,11.967,23.287,14.323,35.746c0.833,5.268-5.403,17.045,5.405,17.045c10.808,0,16.901,1.52,16.629-5.82
	c-0.274-7.342-2.221-9.293-6.65-9.43c-4.436-0.133-8.733-2.344-7.76-11.766c0.972-9.43-1.803-33.381,5.123-42.811
	c1.252-1.664,20.924,0.41,32.146-18.98c0.692,5.688-1.523,13.16-1.523,13.16s21.748-17.316,14.542-42.392
	c-0.968-2.074,1.941-2.213,3.465-0.279c0-6.373-5.261-14.408-5.261-14.408s-0.004-3.742,3.737-0.693
	c3.743,3.059,16.073,10.531,17.736-8.451C247.996,77.038,253.128,71.495,243.153,65.825z"/>
  </svg>
)
export const animals = [
  Mouse, Chicken, Cat, Fox, Dog, Lion, Dolphin, Elephant
]
