import React, { Component } from 'react';

export const Ear = (props) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path d="M4574.9,5001.6c-588-69-1126.2-333.3-1582.1-775.7c-578.4-559.3-971.1-1319.7-1135.8-2200.7c-76.6-408-69-1101.3,13.4-1260.3c30.6-57.5,116.8-111.1,180-111.1c61.3,0,141.7,47.9,172.4,105.3c23,42.1,23,82.4,3.8,254.7c-30.6,275.8-28.7,473.1,5.7,745.1c78.5,599.5,298.8,1193.3,624.4,1683.6c176.2,264.3,528.6,622.5,770,781.5c434.8,287.3,913.6,408,1490.2,371.6c275.8-15.3,526.7-68.9,854.2-178.1c1011.3-337.1,1585.9-913.6,1769.8-1771.7c61.3-289.2,63.2-875.3,1.9-1235.4C7564.8,366.4,7053.4-847.9,6300.6-2018.2c-63.2-97.7-155.2-283.5-214.5-431c-469.3-1170.3-1080.3-1815.7-1811.9-1915.4c-701-95.8-1484.4,490.3-1668.3,1245c-15.3,57.5-26.8,191.5-26.8,296.9c-1.9,227.9-26.8,283.5-143.6,318c-88.1,26.8-159,0-220.3-84.3c-36.4-49.8-40.2-80.4-38.3-287.3c1.9-285.4,44-457.8,178.1-731.7c434.8-898.3,1404-1363.7,2281.2-1097.5c296.9,90,572.7,264.3,848.5,530.6c388.8,377.3,697.2,867.7,971.1,1543.8c65.1,162.8,158.9,342.8,268.1,517.1C7524.5-832.6,8034,452.6,8170,1534.8c44,346.7,30.6,921.3-26.8,1191.4c-195.4,904-741.2,1536.1-1674,1940.2C5808.4,4951.8,5151.4,5068.6,4574.9,5001.6z"/>
        <path d="M4697.5,3816c-273.9-49.8-599.5-159-852.3-287.3c-183.9-91.9-220.3-116.8-249-178.1c-30.6-61.3-32.6-82.4-13.4-139.8c28.7-86.2,103.4-143.6,185.8-143.6c38.3,0,134.1,34.5,239.4,88.1c229.8,114.9,570.8,226,817.8,268.1c233.7,38.3,549.7,28.7,748.9-23c452-116.8,808.3-423.3,1095.6-944.3c86.2-153.2,160.9-206.9,266.3-183.9c76.6,17.2,151.3,111.1,151.3,193.5c0,82.4-84.3,252.8-233.7,469.3c-302.6,444.4-674.2,720.2-1145.4,852.3C5457.9,3856.2,4992.5,3869.6,4697.5,3816z"/>
        <path d="M4638.1,1781.9c-270.1-34.5-685.7-206.9-829.4-344.8c-61.3-57.5-72.8-80.4-72.8-147.5c0-99.6,70.9-189.6,162.8-206.9c55.5-9.6,91.9,3.8,235.6,88.1c312.2,178.1,614.8,249,840.8,193.5c304.6-74.7,588-490.3,553.5-814c-13.4-126.4-97.7-327.5-204.9-484.6c-34.5-51.7-178.1-241.3-318-423.3c-609.1-793-754.6-1112.8-848.5-1867.5c-26.8-206.9-49.8-249-130.2-231.7c-270.1,59.4-417.5,530.5-438.6,1396.3L3577-621.9l-53.6,53.6c-95.8,97.7-254.7,70.9-314.1-51.7c-23-49.8-28.7-136-28.7-406.1c0-1036.2,239.4-1668.3,683.8-1806.2c149.4-46,233.7-47.9,362-9.6c122.6,36.4,224.1,136,273.9,266.2c19.2,53.7,51.7,210.7,69,346.7c86.2,641.6,206.9,900.2,775.7,1637.6c308.4,402.2,390.7,524.8,478.8,716.3c91.9,197.3,114.9,298.8,114.9,484.6c0,254.7-86.2,492.2-273.9,747C5427.2,1676.5,5044.2,1835.5,4638.1,1781.9z"/>
      </g>
    </svg>
  )
}

export const Eraser = (props) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" >

		<path d="M459.174,470.211c-0.15-0.729-0.37-1.458-0.659-2.163c-0.289-0.694-0.648-1.365-1.064-1.99
			c-0.428-0.636-0.914-1.226-1.446-1.758c-0.532-0.544-1.122-1.018-1.758-1.446c-0.625-0.416-1.296-0.775-1.99-1.064
			c-0.706-0.289-1.434-0.509-2.163-0.659c-1.492-0.301-3.031-0.301-4.523,0c-0.74,0.15-1.469,0.37-2.163,0.659
			c-0.706,0.289-1.377,0.648-2.001,1.064c-0.636,0.428-1.226,0.902-1.758,1.446c-0.532,0.532-1.018,1.122-1.434,1.758
			c-0.416,0.625-0.775,1.296-1.064,1.99c-0.289,0.706-0.509,1.434-0.659,2.163c-0.15,0.752-0.231,1.515-0.231,2.267
			s0.081,1.515,0.231,2.256c0.15,0.74,0.37,1.469,0.659,2.163c0.289,0.706,0.648,1.377,1.064,2.001
			c0.416,0.636,0.902,1.226,1.434,1.758c0.532,0.532,1.122,1.018,1.758,1.434c0.625,0.416,1.296,0.775,2.001,1.064
			c0.694,0.289,1.423,0.509,2.163,0.659c0.74,0.15,1.504,0.231,2.256,0.231c0.752,0,1.515-0.081,2.267-0.231
			c0.729-0.15,1.458-0.37,2.163-0.659c0.694-0.289,1.365-0.648,1.99-1.064c0.636-0.416,1.226-0.902,1.758-1.434
			s1.018-1.122,1.446-1.758c0.416-0.625,0.775-1.296,1.064-2.001c0.289-0.694,0.509-1.423,0.659-2.163
			c0.15-0.74,0.22-1.504,0.22-2.256S459.324,470.963,459.174,470.211z"/>

		<path d="M341.893,411.764l151.069-151.07c25.383-25.382,25.383-66.684,0-92.066L371.326,46.991
			c-25.382-25.382-66.684-25.382-92.066,0L128.19,198.06c-0.002,0.002-0.003,0.005-0.006,0.006L13.988,312.262
			c-18.651,18.652-18.651,49,0,67.651l80.996,80.996H53.381c-6.389,0-11.567,5.179-11.567,11.567
			c0,6.389,5.179,11.567,11.567,11.567h69.529h141.912h149.115c6.389,0,11.567-5.179,11.567-11.567
			c0-6.389-5.179-11.567-11.567-11.567H292.749l49.138-49.138C341.89,411.768,341.891,411.767,341.893,411.764z M260.031,460.906
			H127.702v0.001l-97.355-97.355c-4.666-4.665-7.235-10.869-7.235-17.467c0-6.598,2.569-12.801,7.235-17.467l106.021-106.022
			l20.968,20.968l-62.942,62.942c-4.517,4.517-4.517,11.842,0,16.36c2.258,2.259,5.219,3.388,8.179,3.388s5.921-1.13,8.179-3.388
			l62.942-62.942l143.658,143.658L260.031,460.906z M190.055,243.567l135.983-135.983c4.517-4.517,4.517-11.842,0-16.36
			c-4.516-4.517-11.842-4.517-16.359,0L173.696,227.208l-20.968-20.968l142.89-142.89c16.363-16.363,42.987-16.363,59.35,0
			l121.636,121.637c16.363,16.362,16.363,42.987,0.001,59.349l-142.891,142.89L190.055,243.567z"/>

		<path d="M89.954,336.388c-0.15-0.729-0.37-1.458-0.659-2.163c-0.289-0.694-0.648-1.365-1.064-1.99
			c-0.428-0.636-0.902-1.226-1.434-1.758c-0.544-0.532-1.134-1.018-1.77-1.446c-0.625-0.416-1.296-0.775-1.99-1.064
			s-1.423-0.509-2.163-0.659c-1.492-0.301-3.031-0.301-4.523,0c-0.74,0.15-1.469,0.37-2.163,0.659s-1.365,0.648-2.001,1.064
			c-0.625,0.428-1.226,0.914-1.758,1.446s-1.018,1.122-1.434,1.758c-0.416,0.625-0.775,1.296-1.064,1.99
			c-0.289,0.706-0.509,1.434-0.659,2.163c-0.15,0.752-0.22,1.515-0.22,2.267c0,0.752,0.069,1.515,0.22,2.256
			c0.15,0.74,0.37,1.469,0.659,2.163c0.289,0.706,0.648,1.376,1.064,2.001c0.416,0.636,0.902,1.226,1.434,1.758
			c0.532,0.532,1.134,1.018,1.758,1.434c0.636,0.416,1.307,0.775,2.001,1.064s1.423,0.509,2.163,0.659
			c0.74,0.15,1.504,0.231,2.256,0.231c0.763,0,1.515-0.081,2.267-0.231c0.74-0.15,1.469-0.37,2.163-0.659s1.365-0.648,1.99-1.064
			c0.636-0.416,1.226-0.902,1.77-1.434c0.532-0.532,1.006-1.122,1.434-1.758c0.416-0.625,0.775-1.296,1.064-2.001
			c0.289-0.694,0.509-1.423,0.659-2.163s0.231-1.504,0.231-2.256C90.185,337.903,90.104,337.14,89.954,336.388z"/>
    </svg>
)}

export const Check = (props) => {
  return (
    <svg version="1.1" id="Check" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 405.272 405.272">
	   <path d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
		c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
		c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"/>
    </svg>
  )
}

export const Home = (props) => {
  return (
    <svg version="1.1" id="Home" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.055 490.055">
  		<path d="M438.499,264.355c-1.2-22.4-5.8-41-14.8-39.4c-8.3,1.5-14.4,17.4-15.3,40.4c-1.7,41.3-3.6,83-3.4,124.4l0.5,50
  			c0.3,5.6-1.4,10.7-3.4,13.4c-0.5,0.6-0.9,1.1-1.3,1.3c-0.2,0.2-0.3,0.2-0.3,0.3h-0.1c0,0-0.1,0.1,0,0c0.1,0,0.3-0.1,0.4-0.1
  			s0.3,0,0.4,0.1s0.4,0.2,0,0.2l-1.6,0.1h-0.2h-0.1c0.5-0.1-1.1,0.2,1-0.2l0,0h-0.4h-0.8h-1.6l-6.3,0.1l-50,1.1l-12.5,0.3l-3.1,0.1
  			c-0.3,0-0.6,0-0.9,0c-0.6-0.1-1.2-0.2-1.7-0.4c-1.1-0.5-2.1-1.4-2.7-2.6c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.6,0,0.2-0.2-0.5
  			l-0.1-1.6l0,0v-0.1v-0.8l-0.1-3.1c2.9-29.9,4.6-59.9,5.6-90.1l0.6-22.6l0.1-5.7v-1.4c0-0.5,0-0.8,0-1.9c0-1.9-0.2-3.8-0.5-5.7
  			c-1.2-7.6-4.9-14.7-10.2-20.2c-5.3-5.5-12.3-9.4-20-11c-2.3-0.5-5.4-0.7-5.7-0.7h-0.7h-1.7h-1.4h-5.7l-22.7-0.1l-46,0.1
  			c-7.7,0.2-15.5,2.8-21.7,7.4c-6.3,4.5-11.1,10.9-13.7,18.3c-0.7,1.8-1.1,3.7-1.6,5.6c-0.2,1.2-0.4,2.4-0.5,3.6l-0.1,0.9v0.4v0.7
  			l-0.1,2.4v1.4v2.8v11.4c0.1,30.3,0.1,60.6-0.5,90.8c-0.1,5.4,3.4,13.8,5.8,16.5c4.9,5.3,9-1.6,12.1-11.8
  			c5.2-16.9,8.9-35.6,11.4-55.2c1.3-9.8,2.3-19.9,3-30.2c0.4-5.1,0.7-10.3,1-15.4l0.3-7.8l0.1-1.9v-1v-0.1c0,1.4,0,0.4,0,0.7l0,0
  			v-0.1v-0.2v-0.5l0.1-1c0-0.2,0.1,0,0.1,0c0,0.1,0,0.1,0.1-0.1c0.5-1.6,2-2.8,3.5-3.1c-0.7-0.3,8.3,0.2,14,0.3l37.6,1.2l18.8,0.6
  			l4.7,0.1h1.2h0.3h0.1c-1.7,0-0.4,0-0.8,0h0.1h0.6l1.1,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.4-0.1-0.1,0.1-0.1,0.1
  			c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.3,0.6,0.3,0.8c0,0.1,0,0.2,0,0.3c0,0.3-0.1-0.4-0.1,1.8l-0.4,18.8
  			c-0.5,25.1-0.9,50.3-0.9,75.5l0.1,18.9l0.1,9.4v2.1l0.1,1.2v0.6l0.1,1.3c0.1,1,0.3,1.9,0.4,2.9c0.7,3.3,1.6,6.2,3,9
  			c5.3,11.4,16.7,19.5,29.1,20.9c1.5,0.2,3.1,0.2,4.6,0.3h2.5l4.7-0.1l9.5-0.3c12.5,0.4,25,0.7,37.5,1.1l14.9,0.3l3.7,0.1h1.9h0.2
  			l1.6-0.1h0.5h0.9c0.1,0,2.4-0.2,4.1-0.6c0.9-0.2,1.8-0.4,2.6-0.6l2-0.6c2.6-0.9,4.9-2.1,7.2-3.4c8.6-5.4,13.7-12.7,17-19.7
  			c3.3-7.1,4.9-14.4,5.2-21.7l0.1-2.7v-1.9v-3.7l0.1-7.4l0.1-14.8v-29.7C441.399,343.255,440.499,303.755,438.499,264.355z"/>
  		<path d="M476.599,241.355c6.6-0.6,5-7.6,0.6-15.8c-14.6-26.9-37.6-53.6-63.3-78.2c-25-24-50.1-48-75.1-72.1
  			c-12.6-12-25.2-23.9-38-35.6l-19.4-17.5l-9.7-8.7l-4.9-4.3l-2.4-2.2l-1.9-1.6l-1.3-1l-0.2-0.2c-7.1-5.7-17.5-5.6-24.4,0.7
  			c-12.1,11-24.1,22.2-35.8,33.5c-8.2,6.8-16.4,13.7-24.5,20.5c-51.6,43.9-102.3,88.5-149.6,136.4c-13.5,13.6-22.3,27.6-15.1,33.5
  			c6.5,5.3,20.7,0.8,35.9-11.6c5.8-4.7,11.7-9.4,17.5-14.2c-1.6,20.4-3,41-2.8,61.4c0.4,37,2.4,74,3.7,111
  			c-2.9,16.6-4.6,33.3-5.6,50l-0.6,12.6l-0.2,6.3l-0.1,3.1v1.6l0.1,2.8c0.3,8.1,3.3,16.1,8.3,22.5c4.9,6.5,11.9,11.4,19.7,13.9
  			c1,0.3,2,0.6,3,0.8l1.5,0.4l1.9,0.3l1.9,0.2l1.5,0.1l1.6,0.1h1.8h0.8h1.6h3.2l12.6-0.1l25.3-0.2h25.2h3.2h1.6h0.8h1.6
  			c4.2-0.1,8.5-1,12.6-2.5c8.1-3.1,15-8.8,19.5-16.2c1.7-2.6,2.5-6.7,2.2-10.3c-0.1-0.9-0.2-1.7-0.3-2.6c-0.1-1-0.4-1.9-0.6-2.7
  			c-0.4-2-1.2-2.2-1.7-2.6c-2.4-1.6-4.6-1.5-6.7-0.3c-1,0.4-2.1,1.8-3.3,3.2c-1.2,1.2-2.4,2.4-3.7,3.6c-4.4,3.8-9.6,5.4-13.9,5.2
  			c-2.2-0.1-4-0.7-5.9-1.5c-2.7-0.8-5.4-1.5-8.1-2.1c-10.9-2.6-22.4-4-34-4.6l-20.9-1.1l-10.4-0.6h-0.7h-0.2h-0.1c0.3,0-0.8,0,0.7,0
  			l0,0h-0.3l-1.3-0.1c-0.6,0-0.4-0.1-0.4-0.1c0.1,0,0.1,0,0.2,0c-0.4-0.2-0.8-0.4-1.1-0.7c-1.3-1-1.9-2.7-1.7-4
  			c0-0.2,0.1-0.3,0.1-0.5l0.1-2.5l0.2-5.2l0.4-10.5c1.1-27.9,1.3-55.8-0.3-83.9l1.1-20.8c1.7-44,2.2-87.9-1.9-131.7
  			c-0.5-5.4-1.7-10.3-3.6-14.1c12.4-10.4,24.7-20.9,36.7-31.7c40.3-36.5,79.7-74.2,119.3-111.4l5,5.3l13.1,13.6
  			c8.8,9,17.8,17.9,26.9,26.7c18.2,17.5,36.9,34.6,55.8,51.6c37.8,33.9,76.4,67,113.3,101.6
  			C465.599,239.555,473.199,241.755,476.599,241.355z"/>
      </svg>
  )
}
