import React, { Component } from 'react';

const Dino1 = (
  <svg key="dino1" viewBox="0 0 304 435" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <path d="M303.9,106.99 C303.9,106.99 303.9,106.99 303.89,106.99 C303.58,106.99 292.68,106.62 292.68,106.62 L290.52,113.56 C290.26,114.41 289.96,115.24 289.64,116.06 C286.06,125.27 278.96,132.8 269.74,136.52 C266.49,137.83 263.08,138.79 259.58,139.38 C221.08,145.85 201.46,162.08 201.46,162.08 C202.79,168.97 203.78,176.29 204.49,183.9 C204.84,187.58 205.11,191.34 205.33,195.15 L180.48,217.5 L144.7,185.32 L108.93,217.5 L75.28,187.24 C74.87,182.85 74.55,178.56 74.31,174.37 C73.49,160 73.62,146.82 74.39,134.88 C74.72,129.79 75.16,124.92 75.69,120.29 L89.03,122.5 L101.88,76.12 L149.36,84 L162.2,37.63 L209.68,45.51 L213.85,30.46 L214.22,29.11 L214.23,29.11 C214.28,29.11 214.34,29.11 214.39,29.12 C216.06,29.17 217.69,29.23 219.3,29.32 C231,29.91 241.13,31.22 249.32,32.65 C264.93,35.39 278.27,45.35 285.4,59.49 C296.7,81.92 303.9,106.99 303.9,106.99 Z" id="Path" fill="#EDC148"></path>
      <path d="M211.15,295.93 C231.19,295.93 247.44,312.18 247.44,332.22 C247.44,352.27 231.19,368.52 211.15,368.52 C191.1,368.52 174.85,352.27 174.85,332.22 C174.85,312.18 191.1,295.93 211.15,295.93 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M151.36,242.72 C159.75,242.72 166.55,249.52 166.55,257.92 C166.55,266.31 159.75,273.11 151.36,273.11 C142.97,273.11 136.16,266.31 136.16,257.92 C136.16,249.52 142.97,242.72 151.36,242.72 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M77.25,319.82 C90.77,319.82 101.73,330.78 101.73,344.3 C101.73,357.82 90.77,368.78 77.25,368.78 C63.73,368.78 52.78,357.82 52.78,344.3 C52.78,330.78 63.73,319.82 77.25,319.82 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M44.78,261.95 C55.26,261.95 63.76,270.45 63.76,280.93 C63.76,291.41 55.26,299.91 44.78,299.91 C34.29,299.91 25.8,291.41 25.8,280.93 C25.8,270.45 34.29,261.95 44.78,261.95 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M269.54,201.76 C281.36,233.47 288.1,265.61 288.1,291.29 C288.1,370.58 223.82,434.86 144.53,434.86 C65.23,434.86 0.96,370.58 0.96,291.29 C0.96,265.54 7.74,233.3 19.61,201.51 L37.37,217.5 L73.15,185.32 L75.28,187.24 L108.93,217.5 L144.7,185.32 L180.48,217.5 L205.33,195.15 L216.26,185.32 L252.04,217.5 L269.54,201.76 Z M247.44,332.22 C247.44,312.18 231.19,295.93 211.15,295.93 C191.1,295.93 174.85,312.18 174.85,332.22 C174.85,352.27 191.1,368.52 211.15,368.52 C231.19,368.52 247.44,352.27 247.44,332.22 Z M166.55,257.92 C166.55,249.52 159.75,242.72 151.36,242.72 C142.97,242.72 136.16,249.52 136.16,257.92 C136.16,266.31 142.97,273.11 151.36,273.11 C159.75,273.11 166.55,266.31 166.55,257.92 Z M101.73,344.3 C101.73,330.78 90.77,319.82 77.25,319.82 C63.73,319.82 52.78,330.78 52.78,344.3 C52.78,357.82 63.73,368.78 77.25,368.78 C90.77,368.78 101.73,357.82 101.73,344.3 Z M63.76,280.93 C63.76,270.45 55.26,261.95 44.78,261.95 C34.29,261.95 25.8,270.45 25.8,280.93 C25.8,291.41 34.29,299.91 44.78,299.91 C55.26,299.91 63.76,291.41 63.76,280.93 Z" id="Shape" fill="#CCCCCC"></path>
      <path d="M204.49,183.9 L180.48,205.5 L144.7,173.32 L108.93,205.5 L75.28,175.24 L74.31,174.37 C74.55,178.56 74.87,182.85 75.28,187.24 L108.93,217.5 L144.7,185.32 L180.48,217.5 L205.33,195.15 C205.11,191.34 204.84,187.58 204.49,183.9 Z" id="Path" fill="#CEA43C"></path>
      <path d="M217.44,17.51 L214.23,29.11 L214.22,29.11 L209.68,45.51 L184.9,41.4 L162.2,37.63 L156.86,56.9 L149.36,84 L101.88,76.12 L89.03,122.5 L75.69,120.29 L75.69,120.28 L60.72,117.8 C61.23,72.01 73.44,30.69 103.39,11.58 C133.27,-7.5 175.76,-1.23 217.44,17.51 Z" id="Path" fill="#CCCCCC"></path>
      <path d="M219.3,29.32 L215.33,44.11 L215.32,44.11 L210.78,60.51 L186,56.4 L163.3,52.63 L157.96,71.9 L150.46,99 L102.98,91.12 L90.13,137.5 L76.79,135.29 L76.79,135.28 L74.39,134.88 C74.72,129.79 75.16,124.92 75.69,120.29 L89.03,122.5 L101.88,76.12 L149.36,84 L162.2,37.63 L209.68,45.51 L214.39,29.12 C216.06,29.17 217.69,29.23 219.3,29.32 Z" id="Path" fill="#CEA43C"></path>
      <path d="M292.68,106.62 L290.52,113.56 C290.26,114.41 289.96,115.24 289.64,116.06 C251.42,113.04 205.1,120.53 186.93,127.7 C167.93,135.2 148.78,130.88 148.78,130.88 C159.31,130.32 170.96,128.04 183.31,122.93 C220.74,107.46 270.68,106.28 292.68,106.62 Z" id="Path" fill="#CEA43C"></path>
      <polygon id="Path" fill="#000000" points="200.11 83.463 168.127 81.779 168.811 68.797"></polygon>
      <path d="M107.78,17.95 C113.843,17.95 118.76,22.867 118.76,28.93 C118.76,34.993 113.843,39.91 107.78,39.91 C101.712,39.91 96.8,34.993 96.8,28.93 C96.8,22.867 101.711,17.95 107.78,17.95 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M185.64,35.7 C185.64,37.67 185.38,39.58 184.9,41.4 L162.2,37.63 L156.86,56.9 C147.82,54.09 141.26,45.66 141.26,35.7 C141.26,23.45 151.18,13.51 163.45,13.51 C175.7,13.51 185.64,23.45 185.64,35.7 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M173.543,379.72 C179.084,379.72 183.576,384.211 183.576,389.759 C183.576,395.3 179.085,399.792 173.543,399.792 C168.001,399.792 163.504,395.301 163.504,389.759 C163.503,384.211 168.001,379.72 173.543,379.72 Z" id="Path" fill="#F2F2F2"></path>
      <path d="M255.543,235.72 C261.084,235.72 265.576,240.211 265.576,245.759 C265.576,251.3 261.085,255.792 255.543,255.792 C250.002,255.792 245.504,251.301 245.504,245.759 C245.503,240.211 250.001,235.72 255.543,235.72 Z" id="Path" fill="#F2F2F2"></path>
  </svg>
)

const Dino2 = (
  <svg key="dino2" viewBox="0 0 347 435" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(2)" transform="translate(173.600917, 217.500000) scale(-1, 1) translate(-173.600917, -217.500000) " fillRule="nonzero">
              <circle id="XMLID_91_" fill="#98C79C" cx="205.468991" cy="284.185367" r="21.2844037"></circle>
              <circle id="XMLID_92_" fill="#ABD1AD" cx="141.61578" cy="326.754174" r="21.2844037"></circle>
              <circle id="XMLID_93_" fill="#ABD1AD" cx="149.597431" cy="276.203716" r="10.6422018"></circle>
              <circle id="XMLID_102_" fill="#98C79C" cx="194.826789" cy="337.396376" r="10.6422018"></circle>
              <path d="M41.088211,267.313486 L41.088211,267.313486 C39.7127064,257.445505 46.5968807,248.329128 56.4661927,246.953624 L100.055321,240.878257 C101.370963,240.694679 102.586835,241.612569 102.770413,242.928211 L107.087156,273.901009 C107.270734,275.216651 106.352844,276.432523 105.037202,276.616101 L61.4480734,282.691468 C51.5787615,284.066972 42.4637156,277.182798 41.088211,267.313486 Z" id="XMLID_47_" fill="#ABD1AD"></path>
              <path d="M54.0743578,307.983991 L54.0743578,307.983991 C52.6988532,298.116009 59.5830275,288.999633 69.4523394,287.624128 L113.041468,281.548761 C114.35711,281.365183 115.572982,282.283073 115.75656,283.598716 L120.073303,314.571514 C120.256881,315.887156 119.338991,317.103028 118.023349,317.286606 L74.4342202,323.361972 C64.5649083,324.737477 55.4498624,317.851972 54.0743578,307.983991 Z" id="XMLID_46_" fill="#ABD1AD"></path>
              <path d="M158.304083,434.884266 L151.517018,434.884266 C139.335688,434.884266 129.459725,425.008303 129.459725,412.826972 L129.459725,343.954633 L180.361376,343.954633 L180.361376,412.826972 C180.361376,425.008303 170.485413,434.884266 158.304083,434.884266 Z" id="XMLID_45_" fill="#ABD1AD"></path>
              <path d="M100.228257,417.155688 L100.228257,411.70156 C100.228257,401.910734 108.164679,393.974312 117.955505,393.974312 L153.94211,393.974312 C164.637523,393.974312 173.308257,402.645046 173.308257,413.340459 L173.308257,415.518119 C173.308257,426.213532 164.637523,434.884266 153.94211,434.884266 L117.955505,434.884266 C108.164679,434.884266 100.228257,426.946514 100.228257,417.155688 Z" id="XMLID_44_" fill="#ABD1AD"></path>
              <path d="M245.00344,434.884266 L238.216376,434.884266 C226.035046,434.884266 216.159083,425.008303 216.159083,412.826972 L216.159083,343.954633 L267.060734,343.954633 L267.060734,412.826972 C267.060734,425.008303 257.184771,434.884266 245.00344,434.884266 Z" id="XMLID_43_" fill="#98C79C"></path>
              <path d="M186.927615,417.155688 L186.927615,411.70156 C186.927615,401.910734 194.864037,393.974312 204.654862,393.974312 L240.641468,393.974312 C251.336881,393.974312 260.007615,402.645046 260.007615,413.340459 L260.007615,415.518119 C260.007615,426.213532 251.336881,434.884266 240.641468,434.884266 L204.654862,434.884266 C194.864037,434.884266 186.927615,426.946514 186.927615,417.155688 Z" id="XMLID_42_" fill="#98C79C"></path>
              <path d="M346.560642,261.492202 C346.560642,180.517018 304.204679,162.663394 304.204679,162.663394 C304.204679,162.663394 341.682523,253.590367 253.441376,254.920642 C198.712523,255.745413 170.08367,221.119679 173.624862,161.801376 C177.871101,90.678211 174.955138,12.3675688 120.413853,2.16834862 C36.0451376,-13.6073853 3.03701835,60.7496789 0.689082569,95.2876147 C-2.38385321,140.489037 38.5127982,164.613578 67.202844,161.801376 C91.2036697,159.448119 108.731376,153.02422 107.111101,161.801376 C101.619725,191.551651 93.8083486,197.379587 93.8083486,281.526147 C93.8083486,379.913303 172.373073,393.523349 173.624862,376.632844 C184.298991,379.281422 214.097156,381.115872 226.835872,377.589312 C228.077018,394.720596 277.667018,371.809266 291.673486,362.255229 C321.285413,342.052339 346.560642,313.801284 346.560642,261.492202 Z" id="XMLID_103_" fill="#ABD1AD"></path>
              <circle id="XMLID_104_" fill="#272525" cx="84.0374771" cy="62.0294037" r="19.9541284"></circle>
              <path d="M173.542385,343.954633 L173.542385,428.749037 C177.736743,424.734266 180.360046,419.091239 180.360046,412.826972 L180.360046,343.954633 L173.542385,343.954633 Z" id="XMLID_105_" fill="#98C79C"></path>
              <g id="XMLID_106_" transform="translate(172.935780, 86.467890)" fill="#98C79C">
                  <path d="M0.606605505,1.26775229 L0.606605505,77.2198165 C0.637201835,76.5879358 0.650504587,75.9693578 0.689082569,75.332156 C2.17366972,50.4480275 2.77894495,24.6872477 0.606605505,1.26775229 Z" id="XMLID_107_"></path>
                  <path d="M131.268899,76.1941743 C131.268899,76.1941743 168.746743,167.121147 80.5055963,168.451422 C31.2854128,169.193716 3.19133028,141.248624 0.606605505,92.4700917 L0.606605505,290.711697 C0.639862385,290.53078 0.674449541,290.351193 0.689082569,290.162294 C11.363211,292.810872 41.1613761,294.645321 53.9000917,291.118761 C55.1412385,308.250046 104.731239,285.338716 118.737706,275.784679 C148.349633,255.58445 173.624862,227.332064 173.624862,175.022982 C173.624862,94.0491284 131.268899,76.1941743 131.268899,76.1941743 Z" id="XMLID_108_"></path>
              </g>
              <circle id="XMLID_109_" fill="#F6D563" cx="205.468991" cy="284.185367" r="21.2844037"></circle>
              <circle id="XMLID_34_" fill="#F6D563" cx="321.206927" cy="293.493303" r="14.6330275"></circle>
              <circle id="XMLID_110_" fill="#FFE477" cx="149.597431" cy="276.203716" r="10.6422018"></circle>
              <circle id="XMLID_138_" fill="#F6D563" cx="194.826789" cy="337.396376" r="10.6422018"></circle>
              <circle id="XMLID_3_" fill="#F6D563" cx="330.541468" cy="267.123257" r="6.24963303"></circle>
          </g>
      </g>
  </svg>
)

const Dino3 = (
  <svg key="dino3" viewBox="0 0 466 435" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(4)" transform="translate(232.987616, 217.500000) scale(-1, 1) translate(-232.987616, -217.500000) " fillRule="nonzero">
              <polygon id="XMLID_475_" fill="#F6D563" points="288.481765 193.584427 292.223034 168.441966 268.214536 156.490913 259.157647 130.98483 231.283978 134.289752 231.263777 134.354396 214.071176 122.983793 198.357307 137.719923 187.136192 130.299334 161.507554 154.332074 175.174365 173.497663 202.850062 172.277508 204.568514 166.073034 229.785046 164.961966 230.203885 163.44822 249.285975 174.579102 248.020031 175.869288 260.827616 200.432647 262.191873 200.202353 255.78 206.73678 268.588932 231.300139 295.90774 226.709071 299.98435 199.309458"></polygon>
              <path d="M62.4837771,275.738824 L62.4837771,275.738824 C58.676517,268.936393 61.3376935,260.72257 67.908483,256.528793 C86.8599381,244.430944 111.438111,204.365155 111.438111,204.365155 L141.497554,242.658622 C141.497554,242.658622 96.934969,272.004288 81.693808,281.163529 C75.0125851,285.179536 66.2910372,282.541254 62.4837771,275.738824 Z" id="XMLID_44_" fill="#FFE477"></path>
              <path d="M43.0501858,220.650046 L43.0501858,220.650046 C41.8650464,212.945294 47.0796594,205.097786 54.8557895,204.5537 C83.9994427,202.514721 111.439458,163.964025 111.439458,163.964025 L124.90695,190.899009 C124.90695,190.899009 83.1577245,230.628111 59.1478793,232.456997 C51.3744427,233.046873 44.2353251,228.354799 43.0501858,220.650046 Z" id="XMLID_43_" fill="#FFE477"></path>
              <path d="M164.716858,377.316037 L160.065186,377.316037 C151.715341,377.316037 144.945232,370.547276 144.945232,362.196084 L144.945232,263.230217 L179.836811,263.230217 L179.836811,362.196084 C179.836811,370.547276 173.066703,377.316037 164.716858,377.316037 Z" id="XMLID_42_" fill="#FFE477"></path>
              <path d="M124.90695,365.164319 L124.90695,361.425743 C124.90695,354.714892 130.347817,349.274025 137.058669,349.274025 L161.727074,349.274025 C169.058777,349.274025 175.001981,355.217229 175.001981,362.548932 L175.001981,364.04113 C175.001981,371.372833 169.058777,377.316037 161.727074,377.316037 L137.058669,377.316037 C130.347817,377.316037 124.90695,371.87517 124.90695,365.164319 Z" id="XMLID_41_" fill="#FFE477"></path>
              <path d="M212.281347,314.985789 L212.281347,349.272678 L204.394783,349.272678 C197.683932,349.272678 192.243065,354.713545 192.243065,361.424396 L192.243065,365.162972 C192.243065,371.873824 197.683932,377.31469 204.394783,377.31469 L227.4013,377.31469 L229.063189,377.31469 L232.052972,377.31469 C240.402817,377.31469 247.172926,370.545929 247.172926,362.194737 L247.172926,314.984443 L212.281347,314.984443 L212.281347,314.985789 Z" id="XMLID_476_" fill="#FFE477"></path>
              <path d="M457.925712,406.190341 C430.764474,376.160526 318.389025,377.197523 299.983003,285.170108 C297.680062,273.654056 282.565495,188.264768 246.113034,163.962678 C206.642508,137.648545 181.555263,151.15644 160.383019,150.495186 C130.703359,149.567276 113.409752,135.854675 101.754985,109.18096 C90.9190402,84.379226 82.2123065,60.1983437 82.4277864,44.1504799 C82.6297988,29.1328793 72.5951703,12.1826935 51.1414551,4.52103715 C28.8365944,-3.44498452 6.44958204,2.15210526 1.1380031,17.0215635 C-4.1722291,31.8910217 9.60366873,50.4034365 31.9085294,58.3694582 C38.6476625,60.7760991 50.7468576,63.4251548 53.2275697,75.4341176 C55.8523839,88.1406966 69.9110991,168.763839 71.0356347,177.43017 C72.9614861,192.275387 72.607291,212.220743 85.6317028,233.558638 C89.2921672,239.554365 142.759458,288.780743 148.019861,290.656765 C148.019861,290.656765 147.269721,298.550062 149.525526,300.051687 C149.525526,300.051687 146.520929,306.443359 148.77808,309.449303 C148.77808,309.449303 247.614659,346.993978 254.378034,356.388901 C261.141409,365.779783 281.438266,384.938638 306.238653,387.936502 C331.04308,390.937059 449.34692,399.273437 454.169628,419.719783 C455.707616,426.240743 446.304613,431.206207 433.505108,429.497183 C412.775944,426.73096 418.852477,431.005542 424.114226,432.504474 C429.375975,434.007446 443.648824,435.505031 450.413545,434.375108 C457.178266,433.247879 475.403824,425.514845 457.925712,406.190341 Z" id="XMLID_477_" fill="#FFE477"></path>
              <path d="M49.4889938,15.8202632 C45.0326006,15.8202632 41.4084985,19.4443653 41.4084985,23.9007585 C41.4084985,28.3571517 45.0326006,31.9812539 49.4889938,31.9812539 C53.945387,31.9812539 57.5694892,28.3571517 57.5694892,23.9007585 C57.5694892,19.4443653 53.9440402,15.8202632 49.4889938,15.8202632 Z" id="XMLID_478_" fill="#000000"></path>
              <path d="M232.646889,314.985789 L232.646889,377.286409 C240.72065,376.972616 247.174272,370.34661 247.174272,362.196084 L247.174272,314.985789 L232.646889,314.985789 L232.646889,314.985789 Z" id="XMLID_479_" fill="#F6D563"></path>
              <polygon id="XMLID_481_" fill="#E0BE5B" points="288.481765 193.584427 292.223034 168.441966 268.214536 156.490913 259.157647 130.98483 232.646889 134.128142 232.646889 164.87308 249.285975 174.579102 248.020031 175.869288 260.827616 200.432647 262.191873 200.202353 255.78 206.73678 268.588932 231.300139 295.90774 226.709071 299.98435 199.309458"></polygon>
              <path d="M457.925712,406.190341 C430.764474,376.160526 318.389025,377.197523 299.983003,285.170108 C297.680062,273.654056 282.565495,188.264768 246.113034,163.962678 C241.425,160.836873 236.945712,158.288824 232.645542,156.197322 L232.645542,343.974567 C244.072709,349.29692 252.558576,353.861053 254.378034,356.388901 C261.141409,365.779783 281.438266,384.938638 306.238653,387.936502 C331.04308,390.937059 449.34692,399.273437 454.169628,419.719783 C455.707616,426.240743 446.304613,431.206207 433.505108,429.497183 C412.775944,426.73096 418.852477,431.005542 424.114226,432.504474 C429.375975,434.007446 443.648824,435.505031 450.413545,434.375108 C457.178266,433.247879 475.403824,425.514845 457.925712,406.190341 Z" id="XMLID_482_" fill="#F6D563"></path>
              <circle id="XMLID_483_" fill="#DF936F" cx="178.77692" cy="190.897663" r="13.4674923"></circle>
              <circle id="XMLID_484_" fill="#DF936F" cx="225.913142" cy="184.163916" r="20.2012384"></circle>
              <circle id="XMLID_486_" fill="#DF936F" cx="246.114381" cy="231.300139" r="13.4674923"></circle>
              <path d="M232.646889,165.137043 L232.646889,203.190789 C240.487663,200.415139 246.114381,192.955495 246.114381,184.163916 C246.114381,175.372337 240.487663,167.912693 232.646889,165.137043 Z" id="XMLID_487_" fill="#DB8269"></path>
          </g>
      </g>
  </svg>
)

const Dino4 = (
  <svg key="dino4" viewBox="0 0 354 434" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="dinosaur-svgrepo-com" transform="translate(177.107143, 217.500000) scale(-1, 1) translate(-177.107143, -217.500000) " fillRule="nonzero">
            <path d="M4.8285,292.236729 L4.8285,292.236729 C-1.35471429,284.915057 -0.432514286,273.966729 6.8904,267.782271 L39.2320286,240.469243 C40.2076714,239.645229 41.6680286,239.768271 42.4920429,240.743914 L61.8992571,263.724343 C62.7232714,264.701229 62.6002286,266.160343 61.6245857,266.984357 L29.2829571,294.297386 C21.9600429,300.4806 11.0117143,299.5584 4.8285,292.236729 Z" id="XMLID_47_" fill="#ABD1AD"></path>
            <path d="M25.2623143,341.191629 L25.2623143,341.191629 C19.0791,333.869957 20.0013,322.921629 27.3242143,316.737171 L59.6658429,289.424143 C60.6414857,288.600129 62.1018429,288.723171 62.9258571,289.698814 L82.3330714,312.679243 C83.1570857,313.654886 83.0340429,315.115243 82.0584,315.939257 L49.7167714,343.252286 C42.3938571,349.436743 31.4455286,348.514543 25.2623143,341.191629 Z" id="XMLID_46_" fill="#ABD1AD"></path>
            <path d="M124.5231,433.813071 L117.995614,433.813071 C106.277957,433.813071 96.7800429,424.313914 96.7800429,412.5975 L96.7800429,346.353214 L145.738671,346.353214 L145.738671,412.5975 C145.738671,424.313914 136.240757,433.813071 124.5231,433.813071 Z" id="XMLID_45_" fill="#ABD1AD"></path>
            <path d="M68.6628857,416.762314 L68.6628857,411.516214 C68.6628857,402.099086 76.2965143,394.465457 85.7136429,394.465457 L120.327214,394.465457 C130.614343,394.465457 138.953914,402.805029 138.953914,413.092157 L138.953914,415.186371 C138.953914,425.4735 130.614343,433.813071 120.327214,433.813071 L85.7136429,433.813071 C76.2977571,433.813071 68.6628857,426.1782 68.6628857,416.762314 Z" id="XMLID_44_" fill="#ABD1AD"></path>
            <path d="M180.170786,346.353214 L180.170786,394.464214 L169.105629,394.464214 C159.6885,394.464214 152.054871,402.097843 152.054871,411.514971 L152.054871,416.761071 C152.054871,426.1782 159.6885,433.811829 169.105629,433.811829 L201.386357,433.811829 L203.717957,433.811829 L207.913843,433.811829 C219.630257,433.811829 229.129414,424.312671 229.129414,412.596257 L229.129414,346.351971 L180.170786,346.351971 L180.170786,346.353214 Z" id="XMLID_882_" fill="#ABD1AD"></path>
            <path d="M172.841657,235.840843 C200.4306,250.257986 234.712329,265.957757 270.219514,275.565043 C272.348529,266.999271 269.329629,250.797386 260.915486,248.5677 C254.764586,246.938314 247.423029,253.223443 242.979814,260.040514 C244.038729,251.259729 241.513243,238.974086 234.446357,236.644971 C227.749843,234.437657 219.017529,241.847571 214.401557,249.174214 C215.7687,240.480429 213.804986,228.408557 206.961814,225.774943 C200.588443,223.322786 191.864829,229.786886 186.8064,236.638757 C188.075357,227.971071 186.076843,216.1602 179.318186,213.560143 C172.946057,211.107986 164.2212,217.572086 159.164014,224.422714 C160.432971,215.755029 158.434457,203.946643 151.674557,201.345343 C143.909186,198.358757 132.656357,208.604871 128.797286,216.6648 C143.574857,221.218629 158.116286,228.146314 172.841657,235.840843 Z" id="XMLID_42_" fill="#8FC397"></path>
            <path d="M19.4370429,34.4308714 L10.2100714,10.7730857 C8.34578571,5.9943 12.3552429,0.989314286 17.4261,1.76485714 L42.5280857,5.60404286 L19.4370429,34.4308714 Z" id="XMLID_41_" fill="#ABD1AD"></path>
            <path d="M285.512871,184.4226 C273.038314,177.644057 258.9729,171.994029 248.554029,162.139414 C227.5572,142.278557 263.010943,130.312329 260.0865,115.595657 C242.062586,119.176329 217.1346,148.915414 234.3432,170.846871 C246.134186,185.874257 264.568243,192.385586 279.494957,203.056757 C310.822414,225.464229 330.070543,262.060157 285.572529,264.985843 C268.198629,266.133 156.805071,221.2323 140.795829,217.733657 C120.908871,213.401057 115.959814,194.753229 116.374929,176.572714 C117.324471,134.836329 150.237814,91.9403571 129.198729,52.0956 C94.5105857,-13.5968571 46.3772143,-1.27641429 28.4676429,5.78798571 C15.1442143,11.0440286 6.90282857,23.1283286 9.25555714,34.9752429 C12.0097286,48.8368286 28.2886714,57.2845286 45.6165857,53.8418143 C57.5492571,51.4716857 66.8657143,44.0804143 70.6116857,35.1815571 C72.8463429,36.8892429 75.081,38.9784857 77.3392714,41.5052143 C92.1765,58.0787143 92.0547,81.4431857 83.5647429,101.226986 C72.4921286,130.716257 60.0188143,159.745671 48.2589,188.986371 C43.3670143,201.130329 24.7527429,277.144714 60.5122286,321.237557 C132.922329,410.523171 255.896829,339.892843 266.981871,339.368357 C325.128943,336.897557 359.219271,299.405529 352.753929,253.7268 C348.5481,224.001386 310.722986,198.100243 285.512871,184.4226 Z" id="XMLID_40_" fill="#ABD1AD"></path>
            <path d="M260.915486,248.5677 C254.764586,246.938314 247.423029,253.224686 242.979814,260.040514 C244.038729,251.259729 241.513243,238.974086 234.446357,236.644971 C227.749843,234.437657 219.017529,241.847571 214.401557,249.174214 C215.7687,240.480429 213.804986,228.408557 206.961814,225.774943 C200.588443,223.322786 191.864829,229.786886 186.8064,236.638757 C188.075357,227.971071 186.076843,216.1602 179.318186,213.5589 C177.788229,212.969786 176.121557,212.9064 174.403929,213.227057 L174.403929,236.6487 C201.723171,250.842129 235.373529,266.135486 270.219514,275.5638 C272.348529,266.999271 269.330871,250.797386 260.915486,248.5677 Z" id="XMLID_883_" fill="#87B98F"></path>
            <path d="M352.755171,253.728043 C348.5481,224.002629 310.722986,198.101486 285.512871,184.4226 C273.038314,177.644057 258.9729,171.994029 248.554029,162.139414 C227.5572,142.278557 263.010943,130.312329 260.0865,115.595657 C242.062586,119.176329 217.1346,148.914171 234.3432,170.846871 C246.134186,185.874257 264.568243,192.385586 279.494957,203.056757 C310.822414,225.464229 330.070543,262.060157 285.572529,264.985843 C273.260786,265.798671 213.744086,243.491871 174.405171,229.176643 L174.405171,365.559086 C221.069486,361.078586 260.896843,339.6567 266.983114,339.3696 C325.130186,336.8988 359.220514,299.405529 352.755171,253.728043 Z" id="XMLID_884_" fill="#98C79C"></path>
            <path d="M180.170786,346.353214 L180.170786,394.464214 L174.405171,394.464214 L174.405171,433.813071 L201.386357,433.813071 L203.717957,433.813071 L207.913843,433.813071 C219.630257,433.813071 229.129414,424.313914 229.129414,412.5975 L229.129414,346.353214 L180.170786,346.353214 Z" id="XMLID_885_" fill="#98C79C"></path>
            <path d="M42.6623143,15.5630571 C38.5497,15.5630571 35.2051714,18.9075857 35.2051714,23.0202 C35.2051714,27.1328143 38.5497,30.4773429 42.6623143,30.4773429 C46.7749286,30.4773429 50.1194571,27.1328143 50.1194571,23.0202 C50.1194571,18.9075857 46.7736857,15.5630571 42.6623143,15.5630571 Z" id="XMLID_886_" fill="#000000"></path>
            <circle id="XMLID_35_" fill="#FFE477" cx="150.329786" cy="312.249214" r="12.4285714"></circle>
            <circle id="XMLID_34_" fill="#F3D363" cx="199.261071" cy="279.050014" r="12.4285714"></circle>
            <circle id="XMLID_33_" fill="#FFE477" cx="76.5711857" cy="285.075386" r="12.4285714"></circle>
            <circle id="XMLID_32_" fill="#FFE477" cx="107.6277" cy="325.837371" r="12.4285714"></circle>
            <circle id="XMLID_3_" fill="#FFE477" cx="122.185286" cy="297.691629" r="6.21428571"></circle>
        </g>
    </g>
  </svg>
)

const Dino5 = (
  <svg key="dino5" viewBox="0 0 435 435" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(5)" transform="translate(217.500000, 217.500000) scale(-1, 1) translate(-217.500000, -217.500000) " fillRule="nonzero">
              <path d="M397.173691,340.435078 L397.173691,404.185518 L330.978076,397.174541 L283.695615,321.522773 L226.956152,236.413154 L160.760537,179.673691 L160.760537,137.119307 C160.760537,108.394014 184.046631,85.1087695 212.771074,85.1087695 L212.771074,85.1087695 C223.217021,85.1087695 231.684229,93.5759766 231.684229,104.021924 L231.684229,151.304385 L264.782461,151.304385 C285.672656,151.304385 302.60877,168.240498 302.60877,189.130693 L302.60877,236.413154 L321.521924,236.413154 C337.19042,236.413154 349.89123,249.113965 349.89123,264.782461 L349.89123,321.521924 L378.260537,321.521924 C388.706484,321.521924 397.173691,329.989131 397.173691,340.435078 Z" id="Path" fill="#494434"></path>
              <path d="M397.173691,404.184668 L397.173691,340.434229 C397.173691,329.988281 388.706484,321.521074 378.260537,321.521074 L326.281436,321.521074 L330.977227,397.172842 L397.173691,404.184668 Z M302.60877,236.413154 L302.60877,189.130693 C302.60877,168.240498 285.673506,151.304385 264.782461,151.304385 L231.684229,151.304385 L226.956152,236.413154 L302.60877,236.413154 L302.60877,236.413154 Z" id="Shape" fill="#776F4F"></path>
              <path d="M435,429.08502 L435,429.176777 C435,432.392549 432.392549,435 429.176777,435 L368.804385,435 C305.04375,435 246.090205,413.957725 198.586846,378.448301 L198.586846,406.630693 C198.586846,422.299189 185.886035,435 170.217539,435 L141.848232,435 L141.848232,435 L75.6517676,435 C59.9832715,435 47.2824609,422.299189 47.2824609,406.630693 L47.2824609,406.630693 C47.2824609,390.963047 59.9832715,378.261387 75.6517676,378.261387 L141.847383,378.261387 L141.847383,322.069072 C118.649648,291.44915 101.523223,255.999199 92.2820215,217.50085 L9.45615234,217.50085 C4.23360352,217.5 0,213.266396 0,208.043848 L0,208.043848 C0,192.375352 12.7008105,179.674541 28.3693066,179.674541 L85.8980566,179.674541 C85.58625,176.536084 85.3160742,173.385732 85.1087695,170.218389 L85.1087695,94.5666211 L54.2781445,94.5666211 C39.969873,94.5666211 28.3693066,82.9660547 28.3693066,68.6577832 L28.3693066,68.6577832 C28.3693066,61.0945605 31.6742871,53.9085645 37.4176465,48.9859277 L80.9941113,11.6345508 C89.7510352,4.12570313 100.905557,0 112.439854,0 L112.439854,0 C139.126934,0 160.760537,21.6336035 160.760537,48.3206836 L160.760537,151.304385 L160.760537,151.931396 C208.080381,156.363809 250.2346,184.726318 272.096748,227.609502 C309.46002,300.895107 370.964092,403.393682 431.096045,423.588047 C433.443516,424.376484 435,426.609258 435,429.08502 Z" id="Path" fill="#80995F"></path>
              <path d="M435,429.08502 L435,429.176777 C435,432.392549 432.392549,435 429.176777,435 L368.804385,435 C359.750098,435 350.795215,434.561602 341.95333,433.730684 C292.899434,395.853398 246.006943,316.09377 215.358135,255.978809 C193.495986,213.096475 151.340918,184.733115 104.021924,180.300703 L104.021924,179.673691 L85.8989062,179.673691 C85.5870996,176.535234 85.3169238,173.384883 85.1096191,170.217539 L85.1096191,94.5657715 L54.2781445,94.5657715 C39.969873,94.5657715 28.3693066,82.9652051 28.3693066,68.6569336 L28.3693066,68.6569336 C28.3693066,61.0937109 31.6742871,53.9077148 37.4176465,48.9850781 L80.9941113,11.6337012 C89.7510352,4.12570313 100.905557,0 112.439854,0 L112.439854,0 C139.126934,0 160.760537,21.6336035 160.760537,48.3206836 L160.760537,132.39123 L160.760537,151.304385 L160.760537,151.931396 C208.080381,156.363809 250.2346,184.726318 272.096748,227.609502 C309.46002,300.895107 370.964092,403.393682 431.096045,423.588047 C433.443516,424.376484 435,426.609258 435,429.08502 Z" id="Path" fill="#8CAE65"></path>
              <path d="M122.935078,35.9350781 L122.935078,37.8263086 C122.935078,43.0488574 118.701475,47.2824609 113.478926,47.2824609 C108.256377,47.2824609 104.022773,43.0488574 104.022773,37.8263086 L104.022773,35.9350781 C104.022773,30.7125293 108.256377,26.4789257 113.478926,26.4789257 C118.701475,26.4780762 122.935078,30.7116797 122.935078,35.9350781 Z" id="Path" fill="#4C443B"></path>
          </g>
      </g>
  </svg>
)

const Dino6 = (
  <svg key="dino6" viewBox="0 0 850 433" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(3)" transform="translate(424.012755, 216.500000) scale(-1, 1) translate(-424.012755, -216.500000) translate(-1.000000, -1.000000)" fillRule="nonzero">
              <path d="M406.0725,300.824694 L406.0725,380.722653 L397.194949,380.722653 C382.54699,380.722653 370.562296,392.707347 370.562296,407.355306 C370.562296,422.003265 382.54699,433.987959 397.194949,433.987959 L406.0725,433.987959 L459.337806,433.987959 L459.337806,380.722653 L459.337806,300.824694 L406.0725,300.824694 L406.0725,300.824694 Z" id="XMLID_801_" fill="#DD916F"></path>
              <g id="XMLID_802_" transform="translate(459.413265, 284.081633)" fill="#DD916F">
                  <polygon id="XMLID_52_" transform="translate(59.225227, 70.389433) rotate(-33.688478) translate(-59.225227, -70.389433) " points="32.5914751 3.80505381 85.8589783 3.80505381 85.8589783 136.973812 32.5914751 136.973812"></polygon>
                  <path d="M88.7466582,66.6681888 L37.0415816,101.139719 C24.8527041,109.264898 21.5302806,125.883673 29.6554592,138.072551 C37.7806378,150.261429 54.3994133,153.583852 66.5882908,145.458673 L118.293367,110.987143 L88.7466582,66.6681888 Z" id="XMLID_51_"></path>
              </g>
              <polygon id="XMLID_803_" fill="#D87D60" points="424.264821 300.824694 424.264821 433.987959 459.337806 433.987959 459.337806 380.722653 459.337806 300.824694"></polygon>
              <path d="M450.708827,69.085102 C430.365918,69.085102 413.889184,73.8634439 400.337602,81.5580612 C397.374719,70.321301 396.582398,59.6571429 396.582398,53.4828061 C396.582398,32.6161224 434.438495,1.73334184 447.643852,1.73334184 C460.84699,1.73334184 498.703087,32.6161224 498.703087,53.4828061 C498.703087,59.6726786 497.906327,70.3701276 494.927908,81.6335204 C482.568138,73.9544388 468.614847,69.085102 450.708827,69.085102 Z" id="XMLID_804_" fill="#D87D60"></path>
              <path d="M447.643852,1.73334184 C442.419413,1.73334184 433.335459,6.57826531 424.262602,13.7135969 L424.262602,72.1478571 C432.28125,70.1903571 441.045612,69.0828827 450.708827,69.0828827 C468.614847,69.0828827 482.568138,73.9522194 494.927908,81.631301 C497.908546,70.3679082 498.703087,59.6682398 498.703087,53.4783673 C498.705306,32.6161224 460.849209,1.73334184 447.643852,1.73334184 Z" id="XMLID_805_" fill="#D46C5B"></path>
              <path d="M529.796709,95.9064031 C515.410638,81.5203316 500.383163,73.2508929 485.360128,69.1072959 C491.210434,59.0667857 498.190408,50.9660204 502.558163,46.6004847 C517.312653,31.8459949 565.919464,36.7774745 575.256429,46.1144388 C584.593393,55.4514031 589.524872,104.055995 574.770383,118.810485 C570.39375,123.187117 562.266352,130.189286 552.19477,136.04625 C548.879005,121.875459 542.456097,108.565791 529.796709,95.9064031 Z" id="XMLID_46_" fill="#D46C5B"></path>
              <path d="M586.391097,162.488036 C572.005026,148.101964 556.977551,139.832526 541.954515,135.688929 C547.804821,125.648418 554.784796,117.547653 559.152551,113.182117 C573.907041,98.4276276 622.513852,103.359107 631.850816,112.696071 C641.187781,122.033036 646.11926,170.637628 631.36477,185.392117 C626.988138,189.76875 618.86074,196.770918 608.789158,202.627883 C605.475612,188.457092 599.052704,175.147423 586.391097,162.488036 Z" id="XMLID_45_" fill="#D46C5B"></path>
              <path d="M645.50227,206.025765 C636.76898,197.290255 627.642857,192.27 618.521173,189.755434 C622.072194,183.658776 626.311224,178.740612 628.963393,176.088444 C637.920842,167.130995 667.43426,170.124949 673.104796,175.793265 C678.773112,181.461582 681.767066,210.975 672.809617,219.932449 C670.15301,222.589056 665.217092,226.841403 659.102679,230.396862 C657.089694,221.794515 653.19023,213.713724 645.50227,206.025765 Z" id="XMLID_44_" fill="#D46C5B"></path>
              <path d="M366.465306,91.5697194 C352.079235,105.955791 343.809796,120.983265 339.666199,136.00852 C329.625689,130.158214 321.524923,123.17824 317.159388,118.810485 C302.404898,104.055995 307.336378,55.4491837 316.673342,46.1122194 C326.010306,36.7752551 374.614898,31.8437755 389.369388,46.5982653 C393.74602,50.974898 400.748189,59.1022959 406.605153,69.1738776 C392.436582,72.4852041 379.126913,78.9081122 366.465306,91.5697194 Z" id="XMLID_43_" fill="#D87D60"></path>
              <path d="M297.744184,134.701301 C286.183393,146.262092 279.538546,158.337781 276.209464,170.41125 C268.14199,165.710587 261.630306,160.099974 258.121454,156.591122 C246.265485,144.735153 250.227092,105.676148 257.730842,98.172398 C265.234592,90.668648 304.293597,86.7070408 316.149566,98.5630102 C319.667296,102.08074 325.293444,108.610179 330.000765,116.704286 C318.613087,119.365332 307.917857,124.525408 297.744184,134.701301 Z" id="XMLID_42_" fill="#D87D60"></path>
              <g id="XMLID_806_" transform="translate(257.448980, 264.107143)" fill="#DD916F">
                  <path d="M26.9855357,0.0688010204 C24.9681122,2.36586735 7.15086735,23.6453571 2.55673469,62.8597194 C-1.74665816,99.5928061 5.97903061,114.729031 13.2208929,120.956633 C21.0730867,127.71023 32.9068622,126.820255 39.6604592,118.970281 C45.5729082,112.096837 40.6480867,103.989413 40.277449,95.2694388 C37.7251531,35.2927041 75.6278571,38.5618622 76.1272194,37.9648469 L55.1673214,24.8060969 L26.9855357,0.0688010204 Z" id="XMLID_41_"></path>
              </g>
              <path d="M122.507985,50.8239796 C126.014617,87.4105867 148.343878,56.6010459 199.0125,95.2117347 C249.681122,133.822423 269.957449,132.592883 285.61301,126.021276 C368.551531,91.2079592 382.156378,41.6845408 482.321786,59.4396429 C581.530638,77.0260714 584.611148,142.666684 608.864617,170.409031 C662.498342,231.757347 796.571556,182.986301 840.24023,259.51523 C868.177883,308.477143 816.230893,398.084923 819.770816,370.153929 C831.018673,281.378418 808.791505,251.82949 735.407449,259.184541 C662.023393,266.539592 607.570714,307.249821 545.592092,332.70176 C450.637806,371.694184 338.154796,327.306429 300.98449,295.928724 C237.729719,242.532474 155.703367,157.461122 122.507985,161.138648 C89.3103827,164.816173 58.7827041,173.123342 39.4185459,170.409031 C22.1006633,167.98102 -19.2642857,151.945944 10.4377806,124.367832 C40.1398469,96.7897194 118.155765,5.43528061 122.507985,50.8239796 Z" id="XMLID_39_" fill="#DD916F"></path>
              <g id="XMLID_807_" transform="translate(195.306122, 213.061224)" fill="#DD916F">
                  <path d="M52.4951786,1.73556122 C49.8896173,3.45114796 26.6104592,19.5794388 11.4942092,56.9250765 C-2.66326531,91.9092857 0.852244898,108.892041 6.29418367,116.975051 C12.1977551,125.741633 24.0825765,128.060893 32.8469388,122.16176 C40.5215816,116.995026 37.8671939,107.686913 39.8557653,99.0046684 C53.544949,39.2942602 89.9606633,52.7504082 90.6131633,52.2976531 L73.5416327,33.6858673 L52.4951786,1.73556122 Z" id="XMLID_38_"></path>
              </g>
              <circle id="XMLID_36_" fill="#FFE477" cx="428.572653" cy="153.490638" r="22.1938776"></circle>
              <path d="M840.24023,259.51523 C796.571556,182.986301 662.498342,231.757347 608.864617,170.409031 C584.611148,142.666684 581.530638,77.0260714 482.321786,59.4396429 C458.900587,55.2871684 440.213342,54.8166582 424.264821,56.9739031 L424.264821,344.923929 C463.263903,350.698776 505.949388,348.980969 545.594311,332.70176 C607.572934,307.249821 662.027832,266.539592 735.409668,259.184541 C808.791505,251.82949 831.020893,281.378418 819.773036,370.153929 C816.230893,398.087143 868.177883,308.477143 840.24023,259.51523 Z" id="XMLID_808_" fill="#D87D60"></path>
              <circle id="XMLID_34_" fill="#F6E277" cx="501.579413" cy="124.727372" r="33.2908163"></circle>
              <circle id="XMLID_33_" fill="#F6E277" cx="551.935102" cy="192.598469" r="22.1938776"></circle>
              <path d="M99.1844388,99.3886224 C91.8404847,99.3886224 85.8681122,105.360995 85.8681122,112.704949 C85.8681122,120.048903 91.8404847,126.021276 99.1844388,126.021276 C106.528393,126.021276 112.500765,120.048903 112.500765,112.704949 C112.500765,105.360995 106.528393,99.3886224 99.1844388,99.3886224 Z" id="XMLID_809_" fill="#000000"></path>
              <path d="M450.165077,148.357194 C447.395281,136.707628 435.909949,129.432474 424.262602,131.747296 L424.262602,175.247296 C427.296505,175.850969 430.499082,175.846531 433.706097,175.085281 C445.633087,172.248903 453.001454,160.281964 450.165077,148.357194 Z" id="XMLID_810_" fill="#F6E277"></path>
          </g>
      </g>
  </svg>
)

const Dino7 = (
  <svg key="dino7" viewBox="0 0 894 433" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(1)" transform="translate(446.526178, 217.500000) scale(-1, 1) translate(-446.526178, -217.500000) translate(-1.000000, 0.000000)" fillRule="nonzero">
              <path d="M521.414686,432.424162 L510.70822,432.424162 C491.493063,432.424162 475.915052,416.846152 475.915052,397.630995 L475.915052,288.990314 L556.207853,288.990314 L556.207853,397.630995 C556.210131,416.846152 540.63212,432.424162 521.414686,432.424162 Z" id="XMLID_43_" fill="#DB8269"></path>
              <path d="M630.834267,423.819817 L621.082068,428.238141 C603.579581,436.170628 582.961492,428.41123 575.029005,410.908743 L530.185288,311.954215 L603.319948,278.812225 L648.163665,377.766754 C656.096152,395.269241 648.336754,415.88733 630.834267,423.819817 Z" id="XMLID_42_" fill="#DB8269"></path>
              <path d="M277.45712,431.576937 L267.160602,428.643534 C248.681073,423.377984 237.967775,404.126387 243.235602,385.646859 L273.011466,281.167147 L350.22966,303.172225 L320.453796,407.651937 C315.185969,426.133743 295.936649,436.844764 277.45712,431.576937 Z" id="XMLID_41_" fill="#DF936F"></path>
              <path d="M385.02055,432.424162 L374.314084,432.424162 C355.098927,432.424162 339.520916,416.846152 339.520916,397.630995 L339.520916,288.990314 L419.813717,288.990314 L419.813717,397.630995 C419.813717,416.846152 404.235707,432.424162 385.02055,432.424162 Z" id="XMLID_40_" fill="#DF936F"></path>
              <path d="M893.168874,157.618037 C711.65089,316.044581 624.076963,0.195863874 412.974424,0.195863874 C258.289791,0.195863874 214.243194,234.246361 116.901126,205.169686 C58.4448691,187.708194 -15.9811257,224.457723 3.0267801,295.713455 C12.8951309,332.708953 69.3881937,328.028717 94.1262565,319.044031 C230.017068,269.697723 333.250995,404.575052 334.499058,372.487539 C361.960995,374.771859 391.741414,376.28411 426.199791,376.28411 C437.473351,376.28411 579.866387,389.186073 582.118822,356.542853 C715.841466,323.280157 838.415812,250.491675 893.168874,157.618037 L893.168874,157.618037 Z" id="XMLID_39_" fill="#DF936F"></path>
              <path d="M446.583298,2.91062827 L446.583298,377.368194 C488.901283,379.627461 580.315052,382.649686 582.116545,356.545131 C715.841466,323.280157 838.415812,250.493953 893.168874,157.620314 C721.59212,307.367356 633.949869,33.3720157 446.583298,2.91062827 Z" id="XMLID_22_" fill="#DB8269"></path>
              <circle id="XMLID_23_" fill="#FFE477" cx="378.258691" cy="114.070209" r="22.7748691"></circle>
              <circle id="XMLID_24_" fill="#FFE477" cx="435.195864" cy="57.1330366" r="34.1623037"></circle>
              <circle id="XMLID_25_" fill="#F6D563" cx="492.133037" cy="114.070209" r="22.7748691"></circle>
              <circle id="XMLID_34_" fill="#F6D563" cx="624.227277" cy="255.274398" r="27.3298429"></circle>
              <circle id="XMLID_33_" fill="#F6D563" cx="578.677539" cy="300.824136" r="18.2198953"></circle>
              <path d="M446.583298,24.9567016 L446.583298,89.3093717 C459.842827,84.6154712 469.358168,72.0004712 469.358168,57.1330366 C469.358168,42.2656021 459.842827,29.6528796 446.583298,24.9567016 Z" id="XMLID_26_" fill="#F6D563"></path>
              <path d="M68.5204712,227.944555 C60.984267,227.944555 54.8555497,234.073272 54.8555497,241.609476 C54.8555497,249.145681 60.984267,255.274398 68.5204712,255.274398 C76.0566754,255.274398 82.1853927,249.145681 82.1853927,241.609476 C82.1853927,234.073272 76.0566754,227.944555 68.5204712,227.944555 Z" id="XMLID_27_" fill="#000000"></path>
          </g>
      </g>
  </svg>
)

const Dino8 = (
  <svg key="dino8" viewBox="0 0 1125 433" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="dinosaur-svgrepo-com-(1)" transform="translate(562.552356, 216.500000) scale(-1, 1) translate(-562.552356, -216.500000) translate(0.052356, 0.000000)" fillRule="nonzero">
              <path d="M636.414686,432.424162 L625.70822,432.424162 C606.493063,432.424162 590.915052,416.846152 590.915052,397.630995 L590.915052,288.990314 L671.207853,288.990314 L671.207853,397.630995 C671.210131,416.846152 655.63212,432.424162 636.414686,432.424162 Z" id="XMLID_43_" fill="#87B98F"></path>
              <path d="M745.834267,423.819817 L736.082068,428.238141 C718.579581,436.170628 697.961492,428.41123 690.029005,410.908743 L645.185288,311.954215 L718.319948,278.812225 L763.163665,377.766754 C771.096152,395.269241 763.336754,415.88733 745.834267,423.819817 Z" id="XMLID_42_" fill="#87B98F"></path>
              <path d="M392.45712,431.576937 L382.160602,428.643534 C363.681073,423.377984 352.967775,404.126387 358.235602,385.646859 L388.011466,281.167147 L465.22966,303.172225 L435.453796,407.651937 C430.185969,426.133743 410.936649,436.844764 392.45712,431.576937 Z" id="XMLID_41_" fill="#87B98F"></path>
              <path d="M500.02055,432.424162 L489.314084,432.424162 C470.098927,432.424162 454.520916,416.846152 454.520916,397.630995 L454.520916,288.990314 L534.813717,288.990314 L534.813717,397.630995 C534.813717,416.846152 519.235707,432.424162 500.02055,432.424162 Z" id="XMLID_40_" fill="#87B98F"></path>
              <path d="M527.974424,0.195863874 C453.020835,0.195863874 384.729334,151.148303 315.237444,172.615774 C241.316222,195.451558 167.048591,88.4648621 116.874346,73.4775059 C105.012514,69.9342535 94.4214749,31.5726292 82.1586126,32.3290955 C69.6103297,33.1031687 66.0539383,64.8314041 54.8287696,73.4775059 C22.9633273,98.021623 -8.20403687,122.020233 3,164.021276 C12.8683508,201.016773 69.3614136,196.336537 94.0994764,187.351851 C229.990288,138.005543 448.250995,404.575052 449.499058,372.487539 C476.960995,374.771859 506.741414,376.28411 541.199791,376.28411 C552.473351,376.28411 694.866387,389.186073 697.118822,356.542853 C830.841466,323.280157 1070.24694,230.262261 1125,137.388622 C1044.73771,207.440512 880.115067,234.184342 792.30279,191.499312 C681.522343,137.649589 645.733188,0.195863874 527.974424,0.195863874 Z" id="XMLID_39_" fill="#87B98F"></path>
              <circle id="XMLID_23_" fill="#FFE477" cx="493.258691" cy="114.070209" r="22.7748691"></circle>
              <circle id="XMLID_24_" fill="#FFE477" cx="550.195864" cy="57.1330366" r="34.1623037"></circle>
              <circle id="XMLID_25_" fill="#F6D563" cx="607.133037" cy="114.070209" r="22.7748691"></circle>
              <circle id="XMLID_34_" fill="#F6D563" cx="739.227277" cy="255.274398" r="27.3298429"></circle>
              <circle id="XMLID_33_" fill="#F6D563" cx="693.677539" cy="300.824136" r="18.2198953"></circle>
              <path d="M76.3874346,91 C68.8512304,91 62.7225131,97.1287173 62.7225131,104.664921 C62.7225131,112.201126 68.8512304,118.329843 76.3874346,118.329843 C83.9236387,118.329843 90.052356,112.201126 90.052356,104.664921 C90.052356,97.1287173 83.9236387,91 76.3874346,91 Z" id="XMLID_27_" fill="#000000"></path>
          </g>
      </g>
  </svg>
)
export const animals = [
  Dino1, Dino2, Dino3, Dino4, Dino5, Dino6, Dino7, Dino8
]
